import * as Yup from 'yup';
import { FIELD_NAME } from './EditPasswordForm.constants';

export const ValidationSchema = Yup.object().shape({
  [FIELD_NAME.PASSWORD_OLD]: Yup.string().required('form.error.required'),
  [FIELD_NAME.PASSWORD_NEW]: Yup.string().required('form.error.required'),
  [FIELD_NAME.PASSWORD_NEW_CONFIRM]: Yup.string()
    .oneOf(
      [Yup.ref(FIELD_NAME.PASSWORD_NEW), null],
      'form.error.password.mismatch',
    )
    .required('form.error.required'),
});
