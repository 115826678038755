import React from 'react';
import { useSelector } from 'react-redux';

import { getUserApplications } from 'store/user/user.selectors';
import { getApplications } from 'store/applications/applications.selectors';
import MyProducts from './MyProducts';
import { getLocale } from 'store/intl/intl.selectors';

const MyProductsContainer: React.FC = () => {
  const locale = useSelector(getLocale);
  const userApplications = useSelector(getUserApplications);
  const applications = useSelector(getApplications);
  const hasActiveProducts = Boolean(userApplications.length);

  return (
    <MyProducts
      products={hasActiveProducts ? userApplications : applications}
      included={hasActiveProducts}
      language={locale.toUpperCase()}
    />
  );
};

export default MyProductsContainer;
