import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography, Grid, Link } from '@material-ui/core';

import { Application } from 'services/api/dashboard.definitions';
import ProductTile from '../ProductTile';

interface Props {
  products: Application[];
  included: boolean;
  language: string;
}

const ProductList: React.FC<Props> = ({ products, included, language }) => (
  <Grid container spacing={2}>
    {products.map((product) => (
      <Grid key={product.id} item xs={12}>
        <ProductTile
          id={product.id}
          name={product.name}
          url={
            included ? product.applicationUrl : product.onboardingUrls[language]
          }
          logoUrl={product.logoUrl}
          included={included}
        />
      </Grid>
    ))}
  </Grid>
);

const MyProducts: React.FC<Props> = ({ products, included, language }) => {
  const intl = useIntl();

  return (
    <>
      <Typography variant="h3" gutterBottom>
        <FormattedMessage id="dashboard.overview.products.title" />
      </Typography>

      {!included && (
        <Typography color="textSecondary" gutterBottom>
          <FormattedMessage
            id="dashboard.overview.products.no_active_products"
            values={{
              a: (t: string) => (
                <Link
                  href={intl.formatMessage({ id: 'general.contact.url' })}
                  target="__blank"
                  rel="noreferrer noopener"
                >
                  {t}
                </Link>
              ),
            }}
          />
        </Typography>
      )}
      <ProductList
        products={products}
        included={included}
        language={language}
      />
    </>
  );
};

export default MyProducts;
