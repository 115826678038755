import { Store } from 'store/reducers';

export const isSubmittingForm = (state: Store) =>
  state.register.isSubmittingForm;

export const hasRegistered = (state: Store) =>
  Boolean(state.register.registeredEmail);

export const getRegisteredEmail = (state: Store) =>
  state.register.registeredEmail;
