import { Color } from '@material-ui/core';

export const brand01: Color = {
  50: '#B6D0E4',
  100: '#B6D0E4',
  200: '#1D7EC9',
  300: '#0D63A5',
  400: '#0D63A5',
  500: '#004F8C',
  600: '#004F8C',
  700: '#004F8C',
  800: '#004F8C',
  900: '#004F8C',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export const brand02: Color = {
  50: '#F2F7FA',
  100: '#F2F7FA',
  200: '#F2F7FA',
  300: '#E9F1F7',
  400: '#CADDEB',
  500: '#CADDEB',
  600: '#CADDEB',
  700: '#CADDEB',
  800: '#CADDEB',
  900: '#CADDEB',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export const neutral01 = '#FFFFFF';
export const neutral02 = '#000000';

export const neutral03: Color = {
  50: '#F7F8FA',
  100: '#F7F8FA',
  200: '#B6C2CB',
  300: '#8898A5',
  400: '#5D6E7B',
  500: '#5D6E7B',
  600: '#5D6E7B',
  700: '#5D6E7B',
  800: '#5D6E7B',
  900: '#5D6E7B',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export const accent01: Color = {
  50: '#F3F8EC',
  100: '#F3F8EC',
  200: '#F3F8EC',
  300: '#F3F8EC',
  400: '#8FBE49',
  500: '#8FBE49',
  600: '#8FBE49',
  700: '#8FBE49',
  800: '#8FBE49',
  900: '#8FBE49',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export const accent02: Color = {
  50: '#FFF1F0',
  100: '#FFF1F0',
  200: '#FFF1F0',
  300: '#FFF1F0',
  400: '#E00202',
  500: '#E00202',
  600: '#E00202',
  700: '#E00202',
  800: '#E00202',
  900: '#E00202',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};
