import React from 'react';
import styled, { css } from 'styled-components';
import {
  withStyles,
  createStyles,
  Container,
  Paper,
  DialogProps,
  DialogContent,
  Box,
} from '@material-ui/core';

// everything is based on the Dialog setup: https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/Dialog/Dialog.js
// but custom as this shouldn't behave as a Dialog component

const scrollBodyStyle = css`
  margin: ${({ theme }) => theme.spacing(14)}px;
`;

const scrollPaperStyle = css`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  height: 100vh;
  max-height: 100vh;
  margin: ${({ theme }) => theme.spacing(8)}px;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    max-height: calc(100% - ${({ theme }) => theme.spacing(8) * 2}px);
  }
`;

const layoutStyle = css`
  min-width: 100%;
  max-width: 100%;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    min-width: 480px;
    max-width: 480px;
  }
`;

const FilteredPaper: React.FC<
  { hasAfter: boolean } & Pick<DialogProps, 'scroll'>
> = ({ hasAfter, scroll, ...rest }) => <Paper {...rest} />;
export const PaperStyled = styled(FilteredPaper).attrs({
  classes: { root: 'root' },
})`
  &.root {
    ${({ scroll }) => {
      if (scroll === 'paper') {
        return scrollPaperStyle;
      } else {
        return scrollBodyStyle;
      }
    }};

    ${({ hasAfter }) => hasAfter && 'margin-bottom: 0;'}

    ${layoutStyle};

    ${({ theme }) => theme.breakpoints.down('lg')} {
      margin: 0;
      box-shadow: none;
      border-radius: 0;
    }
  }
`;

export const PaperAfter = styled(Box)`
  ${layoutStyle};
`;

export const ContainerStyled = styled(({ scroll, ...rest }) => (
  <Container {...rest} />
)).attrs({
  classes: { root: 'root', maxWidthLg: 'maxWidthLg' },
})`
  &.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 100%;
    max-width: 100%;

    ${({ scroll }) => scroll === 'paper' && `height: 100%;`}
  }

  &.maxWidthLg {
    min-width: 100%;
    max-width: 100%;
  }
`;

export const DialogContentStyled = withStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(10, 10, 6),
      '&:first-child': {
        paddingTop: theme.spacing(10),
      },
      [theme.breakpoints.down('lg')]: {
        padding: theme.spacing(8, 6, 6),
        '&:first-child': {
          paddingTop: theme.spacing(8),
        },
      },
    },
    dividers: {
      borderColor: theme.palette.brand02[300],
      borderBottom: 0,
    },
  }),
)(DialogContent);
