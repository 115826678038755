import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Switch,
  SwitchProps,
  FormControlLabel,
  FormControlLabelProps,
} from '@material-ui/core';
import { useField } from 'formik';

import HelperText from 'components/@common/HelperText';

const FormSwitch: React.FC<
  SwitchProps & Partial<Pick<FormControlLabelProps, 'label'>>
> = ({ name = '---DEFAULT_NAME---', label, ...rest }) => {
  const [field, meta] = useField({ name, type: 'checkbox' });
  const SwitchField = <Switch {...field} {...rest} />;

  return (
    <>
      {label ? (
        <FormControlLabel label={label} control={SwitchField} />
      ) : (
        SwitchField
      )}
      {Boolean(meta.error) && meta.touched && (
        <HelperText variant="error">
          <FormattedMessage id={meta.error} />
        </HelperText>
      )}
    </>
  );
};

export default FormSwitch;
