import React from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { gaService } from 'services';
import userSlice from 'store/user/user.slice';
import { getUser, getIsUserUpdating } from 'store/user/user.selectors';
import EditProfileForm from './EditProfileForm';
import { EditProfileValues } from './EditProfileForm.types';
import { FIELD_NAME } from './EditProfileForm.constants';
import { ValidationSchema } from './EditProfileForm.validation';

interface Props {
  onSuccess?: () => void;
}

const EditProfileFormContainer: React.FC<Props> = ({
  onSuccess = () => console.info('success'),
}) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const isUserUpdating = useSelector(getIsUserUpdating);

  const handleSubmit = (values: EditProfileValues) => {
    gaService.trackEditUserProfile();

    dispatch(
      userSlice.actions.UPDATE_USER_START({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        onSuccess,
      }),
    );
  };

  return (
    <Formik<EditProfileValues>
      initialValues={{
        [FIELD_NAME.FIRST_NAME]: user.firstName ?? '',
        [FIELD_NAME.LAST_NAME]: user.lastName ?? '',
        [FIELD_NAME.EMAIL]: user.email ?? '',
      }}
      validationSchema={ValidationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      <EditProfileForm
        isSubmitting={isUserUpdating}
        isEmailDisabled={!user.emailChangeAllowed}
      />
    </Formik>
  );
};

export default EditProfileFormContainer;
