import React, { Component } from 'react';

interface State {
  error?: Error;
}

class ErrorBoundary extends Component {
  public state: State = { error: undefined };

  public static getDerivedStateFromError(error: Error) {
    return { error };
  }

  public render() {
    const { error } = this.state;
    const { children } = this.props;

    const message = error?.message;

    if (Boolean(error)) {
      // render any custom fallback UI
      return (
        <React.Fragment>
          <h1>Something went wrong...</h1>
          {message && <p>{message}</p>}
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </React.Fragment>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
