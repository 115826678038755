import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga';

import registerSlice from 'store/register/register.slice';
import { isSubmittingForm } from 'store/register/register.selectors';
import { getUserCookies } from 'store/user/user.selectors';
import RegisterForm from './RegisterForm';
import { FIELD_NAME } from './RegisterForm.constants';
import { RegisterValues } from './RegisterForm.types';
import { ValidationSchema } from './RegisterForm.validation';

interface Props {
  initialFirstname?: string;
  initialLastname?: string;
  initialEmail?: string;
}

const RegisterFormContainer: React.FC<Props> = ({
  initialFirstname = '',
  initialLastname = '',
  initialEmail = '',
}) => {
  const dispatch = useDispatch();
  const isSubmitting = useSelector(isSubmittingForm);
  const cookies = useSelector(getUserCookies);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const handleSubmit = (values: RegisterValues) => {
    dispatch(registerSlice.actions.FORM_SUBMIT_START({ formValues: values }));
  };

  return (
    <Formik<RegisterValues>
      initialValues={{
        [FIELD_NAME.FIRST_NAME]: initialFirstname,
        [FIELD_NAME.LAST_NAME]: initialLastname,
        [FIELD_NAME.EMAIL]: initialEmail,
        [FIELD_NAME.ACCEPT_TERMS]: false,
        [FIELD_NAME.ACCEPT_PRIVACY]: false,
      }}
      validationSchema={ValidationSchema}
      validateOnMount
      isInitialValid={false}
      onSubmit={handleSubmit}
    >
      <RegisterForm
        isSubmitting={isSubmitting}
        enableAutoFocus={Boolean(cookies)}
      />
    </Formik>
  );
};

export default RegisterFormContainer;
