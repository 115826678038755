import React from 'react';
import { ConsentOverlay, ConsentForm } from './components';

interface Props {
  open: boolean;
}

const Consent: React.FC<Props> = ({ open }) => (
  <ConsentOverlay open={open}>
    <ConsentForm />
  </ConsentOverlay>
);

export default Consent;
