import React from 'react';
import { useTheme, Box, Theme } from '@material-ui/core';

import { ReactComponent as IcoInfo } from 'assets/svg/u_ico_feedback_info.svg';
import { ReactComponent as IcoError } from 'assets/svg/u_ico_feedback_error.svg';
import { ReactComponent as IcoWarning } from 'assets/svg/u_ico_feedback_warning.svg';
import { ReactComponent as IcoSuccess } from 'assets/svg/u_ico_feedback_succes.svg';

type Variant = 'default' | 'info' | 'warning' | 'error' | 'success';

interface Props {
  variant?: Variant;
}

const getIcon = (variant: Variant, theme: Theme) => {
  switch (variant) {
    case 'warning': // warning doesn't exist (yet)
      return { color: theme.palette.brand01[300], Component: IcoWarning };
    case 'error':
      return { color: theme.palette.error.main, Component: IcoError };
    case 'success':
      return { color: theme.palette.success.main, Component: IcoSuccess };
    case 'info':
      return { color: theme.palette.info.main, Component: IcoInfo };
    default:
      return { color: theme.palette.neutral03[300], Component: null };
  }
};

const HelperText: React.FC<Props> = ({
  variant = 'default',
  children,
  ...rest
}) => {
  const theme = useTheme();
  const icon = getIcon(variant, theme);

  return (
    <Box
      component="span"
      display="flex"
      alignItems="center"
      color={icon.color}
      {...rest}
    >
      {icon.Component && (
        <Box clone mr={2} flexShrink={0}>
          <icon.Component width={14} height={14} fill={icon.color} />
        </Box>
      )}
      {children}
    </Box>
  );
};

export default HelperText;
