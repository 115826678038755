import React from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { gaService } from 'services';
import { UserLanguage } from 'services/api/user.definitions';
import { getLocale } from 'store/intl/intl.selectors';
import userSlice from 'store/user/user.slice';
import { getUser, getIsUserUpdating } from 'store/user/user.selectors';
import EditLanguageForm from './EditLanguageForm';
import { EditLanguageValues } from './EditLanguageForm.types';
import { FIELD_NAME } from './EditLanguageForm.constants';

interface Props {
  onSuccess?: () => void;
}

const EditLanguageFormContainer: React.FC<Props> = ({
  onSuccess = () => console.info('success'),
}) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const isUserUpdating = useSelector(getIsUserUpdating);
  const locale = useSelector(getLocale);

  const handleSubmit = (values: EditLanguageValues) => {
    gaService.trackEditUserLanguage();

    dispatch(
      userSlice.actions.UPDATE_USER_START({
        language: values.language as UserLanguage,
        onSuccess,
      }),
    );
  };

  return (
    <Formik<EditLanguageValues>
      initialValues={{
        [FIELD_NAME.LANGUAGE]: user.language ?? locale,
      }}
      onSubmit={handleSubmit}
    >
      <EditLanguageForm isSubmitting={isUserUpdating} />
    </Formik>
  );
};

export default EditLanguageFormContainer;
