/* eslint-disable no-extend-native */

/** IMPORTANT
 * !! only disable rules when they do not apply to your project !!
 */

/**
 * why: IE11 support
 * docs: https://github.com/facebook/create-react-app/releases?after=v2.0.5
 * "Polyfills for IE 9, IE 10, and IE 11 are no longer included by default (but you can opt in!)"
 */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

/**
 * why: IE11 & Safari 12 support
 * docs: https://github.com/formatjs/react-intl/blob/master/docs/Upgrade-Guide.md#migrate-to-using-native-intl-apis
 * "If you're supporting browsers that do not have Intl.PluralRules (e.g IE11 & Safari 12-), include this polyfill in your build."
 */
import 'intl-pluralrules';

/**
 * why: IE11, Edge & Safari 12 support
 * docs: https://github.com/formatjs/react-intl/blob/master/docs/Upgrade-Guide.md#migrate-to-using-native-intl-apis
 * "If you're supporting browsers that do not have Intl.RelativeTimeFormat (e.g IE11, Edge, Safari 12-),
 * include this polyfill in your build along with individual CLDR data for each locale you support."
 */
import '@formatjs/intl-relativetimeformat/polyfill';

/**
 * https://github.com/okta/okta-oidc-js/issues/720
 */
import TextEncodingShim from 'text-encoding-shim';
import 'webcrypto-shim';

/**
 * why: redux-act support
 * docs: https://github.com/pauldijou/redux-act/pull/77
 */
if (!String.prototype.startsWith) {
  Object.defineProperty(String.prototype, 'startsWith', {
    value: function (search: string, pos: number) {
      pos = !pos || pos < 0 ? 0 : +pos;
      return this.substring(pos, pos + search.length) === search;
    },
  });
}

/**
 * why: useful functionality
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/endsWith#Polyfill
 */
if (!String.prototype.endsWith) {
  String.prototype.endsWith = function (search, endPosition) {
    if (endPosition === undefined || endPosition > this.length) {
      endPosition = this.length;
    }
    return this.substring(endPosition - search.length, endPosition) === search;
  };
}

/**
 * why: Okta for IE11
 */
if (typeof window.TextEncoder === 'undefined') {
  window.TextEncoder = TextEncodingShim.TextEncoder as any;
  window.TextDecoder = TextEncodingShim.TextDecoder as any;
}
