import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import StylesProvider from '@material-ui/styles/StylesProvider';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import GlobalStyle from './global-style';
import { theme01 as theme } from './../../theme';

const themeConnector = <T extends {}>(Component: React.ComponentType<T>) =>
  React.forwardRef((props: T, ref) => (
    <>
      {/* Make sure Material UI styles are inject first */}
      <StylesProvider injectFirst>
        {/* Wrap with Material UI theme provider */}
        <MuiThemeProvider theme={theme}>
          {/* Wrap with Styled Components theme provider */}
          <StyledThemeProvider theme={theme}>
            <>
              {/* Apply Material UI baseline */}
              <CssBaseline />
              <GlobalStyle />
              <Component ref={ref} {...props} />
            </>
          </StyledThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </>
  ));

export default themeConnector;
