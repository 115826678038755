import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, useFormikContext } from 'formik';
import {
  Grid,
  FormGroup,
  Button,
  Link,
  CircularProgress,
} from '@material-ui/core';

import { FormTextField, FormCheckbox } from 'components/@common/Form';
import { FIELD_NAME } from './RegisterForm.constants';

interface Props {
  isSubmitting: boolean;
  enableAutoFocus?: boolean;
}

const RegisterForm: React.FC<Props> = ({
  isSubmitting,
  enableAutoFocus = true,
}) => {
  const intl = useIntl();
  const form = useFormikContext();

  return (
    <Form>
      <Grid container spacing={7}>
        <Grid item xs={12}>
          <FormTextField
            name={FIELD_NAME.FIRST_NAME}
            label={<FormattedMessage id="form.firstname.label" />}
            fullWidth
            autoFocus={enableAutoFocus}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            name={FIELD_NAME.LAST_NAME}
            label={<FormattedMessage id="form.lastname.label" />}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            name={FIELD_NAME.EMAIL}
            type="email"
            label={<FormattedMessage id="form.email.label" />}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <FormCheckbox
              name={FIELD_NAME.ACCEPT_TERMS}
              label={
                <FormattedMessage
                  id="register.terms.label"
                  values={{
                    a: (t: string) => (
                      <Link
                        href={intl.formatMessage({
                          id: 'register.terms.link',
                        })}
                        target="__blank"
                        rel="noreferrer noopener"
                      >
                        {t}
                      </Link>
                    ),
                  }}
                />
              }
            />
            <FormCheckbox
              name={FIELD_NAME.ACCEPT_PRIVACY}
              label={
                <FormattedMessage
                  id="register.privacy.label"
                  values={{
                    a: (t: string) => (
                      <Link
                        href={intl.formatMessage({
                          id: 'register.privacy.link',
                        })}
                        target="__blank"
                        rel="noreferrer noopener"
                      >
                        {t}
                      </Link>
                    ),
                  }}
                />
              }
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={!form.isValid || isSubmitting}
          >
            {isSubmitting ? (
              <CircularProgress color="secondary" size={28} />
            ) : (
              <FormattedMessage id="register.create_account.button" />
            )}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

export default RegisterForm;
