import React from 'react';
import Page from 'components/@layout/Page';
import Title from './components/Title';
import ItsmeSettingsForm from './components/ItsmeSettingsForm';

interface Props {
  onBack?(): void;
  onSuccess?(): void;
}

const ItsmeSettings = ({ onBack, onSuccess }: Props) => {
  return (
    <Page scroll="paper" DialogTitleComponent={<Title onBack={onBack} />}>
      <ItsmeSettingsForm onSuccess={onSuccess} />
    </Page>
  );
};

export default ItsmeSettings;
