import React from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import activationSlice from 'store/activation/activation.slice';
import { getIsActivationCompleting } from 'store/activation/activation.selectors';
import SetPasswordForm from './SetPasswordForm';
import { SetPasswordValues } from './SetPasswordForm.types';
import { FIELD_NAME } from './SetPasswordForm.constants';
import { ValidationSchema } from './SetPasswordForm.validation';

const SetPasswordFormContainer: React.FC = () => {
  const dispatch = useDispatch();
  const isCompleting = useSelector(getIsActivationCompleting);

  const handleSubmit = (values: SetPasswordValues) => {
    dispatch(
      activationSlice.actions.COMPLETE_ACTIVATION_START({
        newPassword: values.password,
      }),
    );
  };

  return (
    <Formik<SetPasswordValues>
      initialValues={{
        [FIELD_NAME.PASSWORD]: '',
        [FIELD_NAME.PASSWORD_CONFIRM]: '',
      }}
      validationSchema={ValidationSchema}
      isInitialValid={false}
      onSubmit={handleSubmit}
    >
      <SetPasswordForm isSubmitting={isCompleting} />
    </Formik>
  );
};

export default SetPasswordFormContainer;
