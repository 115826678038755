import React from 'react';
import { Box, Typography, Link, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { isDev } from 'utils/env';

interface Props {
  firstName?: string;
  lastName?: string;
  email?: string | null;
  onEdit?: () => void;
}

const ProfileData: React.FC<Props> = ({
  firstName = isDev() ? 'Samuel' : undefined,
  lastName = isDev() ? 'De Pooter' : undefined,
  email = isDev() ? 'samuel@novemberfive.co' : undefined,
  onEdit = () => console.info('clicked edit'),
}) => (
  <section>
    <Box display="flex" justifyContent="space-between" mb={4}>
      <Typography component="h3" variant="h3" color="textPrimary">
        <FormattedMessage id="dashboard.profile.profile_data.title" />
      </Typography>
      <Link component="button" onClick={onEdit}>
        <FormattedMessage id="general.edit" />
      </Link>
    </Box>

    <Grid container spacing={4}>
      {firstName && (
        <Grid item xs={12}>
          <Box color="neutral03.300">
            <Typography color="inherit">
              <FormattedMessage id="form.firstname.label" />
            </Typography>
          </Box>
          <Typography>{firstName}</Typography>
        </Grid>
      )}

      {lastName && (
        <Grid item xs={12}>
          <Box color="neutral03.300">
            <Typography color="inherit">
              <FormattedMessage id="form.lastname.label" />
            </Typography>
          </Box>
          <Typography>{lastName}</Typography>
        </Grid>
      )}

      {email && (
        <Grid item xs={12}>
          <Box color="neutral03.300">
            <Typography color="inherit">
              <FormattedMessage id="form.email.label" />
            </Typography>
          </Box>
          <Typography>{email}</Typography>
        </Grid>
      )}
    </Grid>
  </section>
);

export default ProfileData;
