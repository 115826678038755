import qs from 'qs';

import { Store } from 'store/reducers';
import { messages } from 'services/i18n/config';
import QUERY_PARAMS from 'constants/query-params';

export const getIntl = (state: Store) => state.intl;

export const getLocale = (state: Store) => getIntl(state).locale;

export const getLocaleQs = (state: Store) =>
  qs.stringify({ [QUERY_PARAMS.FORCE_LANGUAGE]: getLocale(state) });

export const getEnableLocaleUpdate = (state: Store) =>
  getIntl(state).enableUpdate;

export const getTranslation = (state: Store, key: string) => {
  const locale = getLocale(state);
  return messages[locale][key] || key;
};
