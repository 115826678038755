import React from 'react';
import { LoginCallback as OktaCallback } from '@okta/okta-react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import qs from 'query-string';
import { decodeState, encodeState } from 'utils/okta.utils';
import { DecodedState, Flows, Origins } from 'types/state.types';
import { envService } from 'services';
import { Routes } from 'pages/routes.constants';

const LoginCallback = (props: RouteComponentProps) => {
  const params = new URLSearchParams(props.location.search);
  const state = params.get('state') ?? undefined;
  const code = params.get('code');

  return (
    <OktaCallback
      errorComponent={() => {
        const decodedState = decodeState<DecodedState>(state);

        if (decodedState?.origin === Origins.App) {
          window.location.replace(
            qs.stringifyUrl({
              url: `${envService.getCallbackItsmeBaseUrl(
                decodedState.origin,
              )}/activateItsme`,
              query: {
                state: encodeState({
                  ...decodedState,
                  flow: Flows.Activate,
                }),
                code,
              },
            }),
          );

          return null;
        }

        return (
          <Redirect
            to={{
              pathname: Routes.ItsmeActivate,
              search: qs.stringify({
                state: encodeState({
                  ...decodedState,
                  flow: Flows.Activate,
                }),
                code,
              }),
            }}
          />
        );
      }}
    />
  );
};

export default LoginCallback;
