import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Container, Box, CircularProgress } from '@material-ui/core';

import activationSlice from 'store/activation/activation.slice';
import ErrorPage from 'components/@layout/ErrorPage';
import {
  getIsActivationLoading,
  getIsActivationExpired,
  getIsActivationRenewing,
  getIsActivationActive,
  getActivationTokenError,
  getRedirectUrl,
} from 'store/activation/activation.selectors';
import ActivationExpired from './components/ActivationExpired';
import ActivationSetPassword from './components/ActivationSetPassword';
import ActivationConfirm from './components/ActivationConfirm';

const Activation = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const loading = useSelector(getIsActivationLoading);
  const error = useSelector(getActivationTokenError);

  const isActive = useSelector(getIsActivationActive);
  const isExpired = useSelector(getIsActivationExpired);
  const isRenewing = useSelector(getIsActivationRenewing);
  const redirectUrl = useSelector(getRedirectUrl);

  useEffect(() => {
    dispatch(activationSlice.actions.GET_ACTIVATION_START());
  }, [dispatch]);

  const handleRenew = () => {
    dispatch(activationSlice.actions.RENEW_ACTIVATION_START());
  };

  if (loading) {
    return (
      <Container fixed>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress size={40} />
        </Box>
      </Container>
    );
  }

  if (Boolean(error)) {
    return (
      <ErrorPage
        title={intl.formatMessage({
          id: 'activation.invalid.title',
        })}
        description={intl.formatMessage({
          id: 'activation.invalid.description',
        })}
      />
    );
  }

  if (isExpired) {
    return <ActivationExpired isRenewing={isRenewing} onRenew={handleRenew} />;
  }

  if (redirectUrl) {
    return <ActivationConfirm redirectUrl={redirectUrl} />;
  }

  if (isActive) {
    return <ActivationSetPassword />;
  }

  return <ErrorPage />;
};

export default Activation;
