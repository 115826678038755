import React from 'react';
import { makeStyles, useTheme, IconButton } from '@material-ui/core';
import { SnackbarProvider, WithSnackbarProps, SnackbarKey } from 'notistack';

import { ReactComponent as IcoSuccess } from 'assets/svg/u_ico_feedback_succes.svg';
import { ReactComponent as IcoError } from 'assets/svg/u_ico_feedback_error.svg';
import { ReactComponent as IcoInfo } from 'assets/svg/u_ico_feedback_info.svg';
import { ReactComponent as IcoClose } from 'assets/svg/u_ico_close.svg';
import Notifier from './Notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    wordBreak: 'break-word',
    [theme.breakpoints.down('lg')]: {
      left: theme.spacing(4),
      right: theme.spacing(4),
      maxWidth: '100%',
    },
  },
  info: {
    fontSize: 'inherit',
    lineHeight: 'inherit',
    fontWeight: 'bold',
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.brand02[200],
  },
  success: {
    fontSize: 'inherit',
    lineHeight: 'inherit',
    fontWeight: 'bold',
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.accent01[200],
  },
  error: {
    fontSize: 'inherit',
    lineHeight: 'inherit',
    fontWeight: 'bold',
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.accent02[200],
  },
}));

const iconDefaultProps = {
  id: 'notistack-icon',
  title: '',
  width: 32,
  height: 32,
};

const notiConnector = <T extends {}>(Component: React.ComponentType<T>) => (
  props: T & { children?: React.ReactNode },
) => {
  const styles = useStyles();
  const theme = useTheme();

  const notistackRef = React.useRef<WithSnackbarProps>();

  const handleDismissClick = (key: SnackbarKey) => () => {
    notistackRef?.current?.closeSnackbar(key);
  };

  return (
    <SnackbarProvider
      ref={notistackRef}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      action={(key) => (
        <IconButton
          title="Close"
          onClick={handleDismissClick(key)}
          style={{ padding: 4 }}
        >
          <IcoClose
            title=""
            width={20}
            height={20}
            fill={theme.palette.neutral03[400]}
          />
        </IconButton>
      )}
      iconVariant={{
        success: (
          <IcoSuccess
            {...iconDefaultProps}
            fill={theme.palette.accent01[400]}
          />
        ),
        error: (
          <IcoError {...iconDefaultProps} fill={theme.palette.accent02[400]} />
        ),
        info: (
          <IcoInfo {...iconDefaultProps} fill={theme.palette.primary.main} />
        ),
      }}
      classes={{
        variantInfo: styles.info,
        variantSuccess: styles.success,
        variantError: styles.error,
        containerAnchorOriginBottomLeft: styles.root,
        containerAnchorOriginBottomRight: styles.root,
      }}
    >
      <Notifier />
      <Component {...props} />
    </SnackbarProvider>
  );
};

export default notiConnector;
