import { useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar, SnackbarKey } from 'notistack';

import notificationSlice from 'store/notification/notification.slice';
import { getNotifications } from 'store/notification/notification.selectors';

const Notifier = () => {
  const displayed = useRef<(string | number | undefined)[]>([]);

  const dispatch = useDispatch();
  const notifications = useSelector(getNotifications);
  const { enqueueSnackbar } = useSnackbar();

  const addDisplayed = useCallback((id?: SnackbarKey) => {
    displayed.current = [...displayed.current, id];
  }, []);

  const removeDisplayed = useCallback((id?: SnackbarKey) => {
    displayed.current = [...displayed.current.filter((key) => id !== key)];
  }, []);

  const handleClose = useCallback(
    (id?: SnackbarKey) => {
      dispatch(notificationSlice.actions.NOTIFICATION_REMOVE(id));
      removeDisplayed(id);
    },
    [dispatch, removeDisplayed],
  );

  useEffect(() => {
    notifications.items.forEach(({ key, content, ...rest }) => {
      if (displayed.current.includes(key)) {
        return;
      }

      addDisplayed(key);
      enqueueSnackbar(content, {
        ...rest,
        onExited: () => {
          handleClose(key);
        },
      });
    });
  }, [notifications, enqueueSnackbar, addDisplayed, handleClose]);

  return null;
};

export default Notifier;
