import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, useFormikContext } from 'formik';
import { Grid, Button, CircularProgress, RadioGroup } from '@material-ui/core';

import options from 'components/MenuLanguage/language-options';
import FormRadio from 'components/@common/Form/FormRadio';
import { FIELD_NAME } from './EditLanguageForm.constants';
import { EditLanguageValues } from './EditLanguageForm.types';

interface Props {
  isSubmitting: boolean;
}

const EditLanguageForm: React.FC<Props> = ({ isSubmitting }) => {
  const form = useFormikContext<EditLanguageValues>();

  return (
    <Form>
      <Grid container spacing={7}>
        <Grid item xs={12}>
          <RadioGroup name={FIELD_NAME.LANGUAGE}>
            {options.map((option) => (
              <FormRadio
                key={option.code}
                label={<FormattedMessage id={option.label} />}
                name={FIELD_NAME.LANGUAGE}
                value={option.code.toUpperCase()}
              />
            ))}
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={!form.isValid || isSubmitting}
          >
            {isSubmitting ? (
              <CircularProgress color="secondary" size={28} />
            ) : (
              <FormattedMessage id="dashboard.settings.edit_language.submit" />
            )}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

export default EditLanguageForm;
