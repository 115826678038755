import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  withStyles,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

interface Props {
  isOpen: boolean;
  onConfirmClick(): void;
  onClose(): void;
}

const ItsmeSettingsUnlinkConfirmDialog = ({
  isOpen,
  onClose,
  onConfirmClick,
}: Props) => {
  return (
    <Dialog maxWidth="xl" open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Typography>
          <FormattedMessage id="dashboard.settings.edit_itsme_settings.section.unlink.confirm_dialog.description" />
        </Typography>
      </DialogTitle>
      <DialogActions disableSpacing={false}>
        <CustomConfirmButton
          type="submit"
          onClick={onConfirmClick}
          color="primary"
          autoFocus
        >
          <FormattedMessage id="dialog.confirmation.action.confirm" />
        </CustomConfirmButton>

        <Button type="button" onClick={onClose} color="primary">
          <FormattedMessage id="dialog.confirmation.action.cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ItsmeSettingsUnlinkConfirmDialog;

const CustomConfirmButton = withStyles((theme) => ({
  root: {
    color: theme.palette.error.main,
  },
}))(Button);
