import React from 'react';
import { ButtonStyled } from './ItsmeButton.style';
import { ReactComponent as ItsmeLogo } from 'assets/svg/itsme_logo.svg';
import { FormattedMessage } from 'react-intl';

interface Props {
  href?: string;
  onClick?(): void;
  type?: 'sign_in' | 'register';
}

const ItsmeButton = ({ href, onClick, type = 'sign_in' }: Props) => {
  return (
    <ButtonStyled onClick={onClick} href={href}>
      <ItsmeLogo style={{ marginRight: 16 }} />
      <FormattedMessage id={`itsme.${type}.button`} />
    </ButtonStyled>
  );
};

export default ItsmeButton;
