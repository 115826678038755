import { Box, Button, Link, Typography } from '@material-ui/core';
import { useOktaAuth } from '@okta/okta-react';
import Page from 'components/@layout/Page';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Wrap, Title } from '../styles';
import { Link as RouterLink } from 'react-router-dom';
import { Routes } from 'pages/routes.constants';

const Activate = () => {
  const { oktaAuth } = useOktaAuth();

  return (
    <Page scroll="paper">
      <Wrap>
        <Box flex={1}>
          <Title variant="h1">
            <FormattedMessage id="ota.confirm.title" />
          </Title>
          <Typography
            style={{
              whiteSpace: 'pre-line',
            }}
            color="textSecondary"
          >
            <FormattedMessage
              id="ota.confirm.description"
              values={{
                b: (chunks: string[]) => <strong>{chunks}</strong>,
              }}
            />
          </Typography>
        </Box>
        <Box>
          <Button
            onClick={() =>
              oktaAuth.signInWithRedirect({
                redirectUri: `${window.location.origin}/itsme/sign-in`,
                extraParams: {
                  hideIdp: 'true',
                },
              })
            }
            fullWidth
            variant="contained"
            color="primary"
          >
            <FormattedMessage id="ota.confirm.button.connect" />
          </Button>
          <Box textAlign="center" mt={2}>
            <Link component={RouterLink} to={Routes.Register}>
              <FormattedMessage id="ota.confirm.button.new_profile" />
            </Link>
          </Box>
        </Box>
      </Wrap>
    </Page>
  );
};

export default Activate;
