import React, { useState } from 'react';
import { Divider, Box } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import { gaService } from 'services';
import userSlice from 'store/user/user.slice';
import { getUser } from 'store/user/user.selectors';
import Page from 'components/@layout/Page';
import Title from './components/Title';
import Password from './components/Password';
import Authentication from './components/Authentication';
import Privacy from './components/Privacy';

import EditPassword from './edit-password';
import EditConsent from './edit-consent';

const DashboardSettings: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  /**
   * we don't want seperate routes for these because the user shouldn't be able
   * to navigate to it directly so to handle this we use local state
   */

  // PASSWORD
  const [editPassword, setEditPassword] = useState(false);

  const handleEditPassword = () => {
    setEditPassword(true);
  };

  const handleEditPasswordClose = () => {
    setEditPassword(false);
  };

  // CONSENT
  const [editConsent, setEditConsent] = useState(false);

  const handleEditConsent = () => {
    setEditConsent(true);
  };

  const handleEditConsentClose = () => {
    setEditConsent(false);
  };

  // MFA

  const handleActivateMFA = () => {
    gaService.trackMFAActivate();
    dispatch(userSlice.actions.ACTIVATE_MFA());
  };

  const handleDeactivateMFA = () => {
    gaService.trackMFADeactivate();
    dispatch(userSlice.actions.DEACTIVATE_MFA());
  };

  const handleResetMFA = () => {
    gaService.trackMFAReset();
    dispatch(userSlice.actions.RESET_MFA());
  };

  if (editPassword) {
    return (
      <EditPassword
        onBack={handleEditPasswordClose}
        onSuccess={handleEditPasswordClose}
      />
    );
  } else if (editConsent) {
    return (
      <EditConsent
        onBack={handleEditConsentClose}
        onSuccess={handleEditConsentClose}
      />
    );
  }

  return (
    <Page scroll="paper" DialogTitleComponent={<Title />}>
      <Password
        updatedDate={user.passwordUpdatedDate}
        onEdit={handleEditPassword}
      />

      <Box mt={6} mb={6}>
        <Divider />
      </Box>

      <Authentication
        required={user.mfa?.required}
        enabled={user.mfa?.enabled}
        methods={user.mfa?.enabledMethods}
        onActivate={handleActivateMFA}
        onDeactivate={handleDeactivateMFA}
        onReset={handleResetMFA}
      />

      <Box mt={6} mb={6}>
        <Divider />
      </Box>

      <Privacy onEditConsent={handleEditConsent} />
    </Page>
  );
};

export default DashboardSettings;
