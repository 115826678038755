import { AxiosInstance } from 'axios';

import RestApiService from './';
import { ActivationStatus } from './activation.definitions';

export async function getActivation(
  this: RestApiService<AxiosInstance>,
  token: string,
) {
  const response = await this.api.get<{
    status: ActivationStatus;
    activationToken: string;
  }>(`/public_api/activations/${token}`);
  return response;
}

export async function renewActivation(
  this: RestApiService<AxiosInstance>,
  token: string,
) {
  const response = await this.api.post<void>('/public_api/activations', {
    activationToken: token,
  });
  return response;
}

export async function completeActivation(
  this: RestApiService<AxiosInstance>,
  activationToken: string,
  newPassword: string,
) {
  const response = await this.api.put<{ redirectUrl: string }>(
    `/public_api/activations/${activationToken}/complete`,
    { newPassword },
  );
  return response;
}
