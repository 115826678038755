import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Radio,
  RadioProps,
  FormControlLabel,
  FormControlLabelProps,
} from '@material-ui/core';
import { useField } from 'formik';

import HelperText from 'components/@common/HelperText';

const FormRadio: React.FC<
  RadioProps & Partial<Pick<FormControlLabelProps, 'label'>>
> = ({ name = '---DEFAULT_NAME---', label, value, ...rest }) => {
  const [field, meta] = useField({ name, type: 'radio' });
  const RadioField = (
    <Radio {...field} {...rest} checked={meta.value === value} value={value} />
  );

  return (
    <>
      {label ? (
        <FormControlLabel label={label} control={RadioField} />
      ) : (
        RadioField
      )}
      {Boolean(meta.error) && meta.touched && (
        <HelperText variant="error">
          <FormattedMessage id={meta.error} />
        </HelperText>
      )}
    </>
  );
};

export default FormRadio;
