import { compose } from 'redux';

import withBoundary from 'components/@boundaries/withBoundary';
import RootBoundary from 'components/@boundaries/RootBoundary';

import connectIntl from './intl';
import connectRedux from './redux';
import connectTheme from './theme';
import connectNotistack from './notistack';

export default compose(
  withBoundary(RootBoundary),
  connectTheme,
  connectRedux,
  connectIntl,
  connectNotistack,
);
