import React, { Component } from 'react';
import ErrorPage from 'components/@layout/ErrorPage';

interface State {
  error?: Error;
}

class ErrorBoundary extends Component<{}, State> {
  public state = {
    error: undefined,
  };

  public static getDerivedStateFromError(error: Error) {
    return { error };
  }

  public render() {
    const { error } = this.state;
    const { children } = this.props;

    if (Boolean(error)) {
      return <ErrorPage />;
    }

    return children;
  }
}

export default ErrorBoundary;
