import React from 'react';
import { Box, Link, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

interface Props {
  onEdit(): void;
}

const ItsmeSettingsEdit = ({ onEdit }: Props) => {
  return (
    <section>
      <Box display="flex" justifyContent="space-between" mb={4}>
        <Typography component="h3" variant="h3" color="textPrimary">
          <FormattedMessage id="dashboard.profile.itsme_settings.title" />
        </Typography>
        <Link component="button" onClick={onEdit}>
          <FormattedMessage id="general.edit" />
        </Link>
      </Box>
    </section>
  );
};

export default ItsmeSettingsEdit;
