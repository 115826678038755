import {
  all,
  call,
  put,
  takeLatest,
  select,
  SagaReturnType,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import qs from 'qs';

import { apiService, envService } from 'services';
import history from 'packages/history';
import QUERY_PARAMS from 'constants/query-params';
import { RegisterValues } from 'pages/register/components/RegisterForm/RegisterForm.types';
import { getLocale, getTranslation } from 'store/intl/intl.selectors';
import { getUserCookies } from 'store/user/user.selectors';
import registerSlice from './register.slice';
import { getQueryParams } from 'store/route/route.selectors';
import { Routes } from 'pages/routes.constants';
import notificationSlice from 'store/notification/notification.slice';
import {
  CustomHttp,
  ErrorTrace,
  MissingOriginInState,
  MissingStateInSearchParams,
} from 'services/error/error.types';
import { decodeState, encodeState } from 'utils/okta.utils';
import { DecodedState, Flows } from 'types/state.types';

function* registerUser({
  payload,
}: PayloadAction<{ formValues: RegisterValues }>) {
  try {
    const params: SagaReturnType<typeof getQueryParams> = yield select(
      getQueryParams,
    );
    const onboardingToken = params[QUERY_PARAMS.ONBOARDING_TOKEN];
    const code = params[QUERY_PARAMS.ITSME_CODE];
    const state = params[QUERY_PARAMS.STATE];

    const cookies: ReturnType<typeof getUserCookies> = yield select(
      getUserCookies,
    );
    const language: ReturnType<typeof getLocale> = yield select(getLocale);

    const userResponse: SagaReturnType<typeof apiService.createUser> = yield call(
      [apiService, apiService.createUser],
      {
        ...payload.formValues,
        language: language.toUpperCase(),
        itsmeCode: code,
        cookies: {
          essential: true,
          analytics: cookies?.analytics ?? false,
        },
      },
      onboardingToken,
    );

    // If we have a `code` we know this is the itsme flow
    if (code) {
      const decodedState = decodeState<DecodedState>(state);
      if (!decodedState) {
        throw new MissingStateInSearchParams();
      }

      if (!decodedState.origin) {
        throw new MissingOriginInState();
      }
      const baseUrl = envService.getCallbackItsmeBaseUrl(decodedState.origin);
      const redirectUri = `${baseUrl}/loginItsme`;

      history.push({
        pathname: Routes.ItsmeCallback,
        search: qs.stringify({
          [QUERY_PARAMS.ITSME_CODE]: code,
          [QUERY_PARAMS.STATE]: encodeState({
            ...decodedState,
            flow: Flows.RegisterForm,
          }),
          redirectUri,
        }),
      });
    } else {
      if (userResponse?.data?.activationToken) {
        history.replace({
          pathname: Routes.UserActivation,
          search: qs.stringify({
            [QUERY_PARAMS.ACTIVATION_TOKEN]: userResponse.data.activationToken,
          }),
        });
      } else {
        history.replace({
          pathname: window.location.pathname,
          search: qs.stringify({
            [QUERY_PARAMS.DEFAULT_FIRSTNAME]: payload.formValues.firstName,
            [QUERY_PARAMS.DEFAULT_LASTNAME]: payload.formValues.lastName,
          }),
        });
      }
    }

    yield put(
      registerSlice.actions.FORM_SUBMIT_SUCCESS({
        email: payload.formValues.email,
        token: userResponse?.data?.activationToken,
      }),
    );
  } catch (e) {
    const error = e as ErrorTrace<CustomHttp>;

    const customError = error.custom?.response?.data;

    if (
      error &&
      error.code === 409 &&
      !(customError && customError.errors.includes('ITSME_ALREADY_EXISTS'))
    ) {
      const accountExistMessage: ReturnType<typeof getTranslation> = yield select(
        getTranslation,
        'form.error.email.in_use',
      );

      yield put(
        notificationSlice.actions.NOTIFICATION_ADD({
          variant: 'error',
          content: accountExistMessage,
        }),
      );
    }

    yield put(registerSlice.actions.FORM_SUBMIT_FAIL(error));
  }
}

const registerSaga = function* () {
  yield all([
    takeLatest(registerSlice.actions.FORM_SUBMIT_START, registerUser),
  ]);
};

export default registerSaga;
