import React from 'react';
import { Theme } from '@material-ui/core';

import { ReactComponent as IcoCheckboxDefault } from 'assets/svg/u_ico_checkbox_default.svg';
import { ReactComponent as IcoCheckboxChecked } from 'assets/svg/u_ico_checkbox_checked.svg';
import { ReactComponent as IcoRadioDefault } from 'assets/svg/u_ico_radio_button_inactive.svg';
import { ReactComponent as IcoRadioChecked } from 'assets/svg/u_ico_radio_button_active.svg';

import * as colors from './colors';
import { createCustomMuiTheme } from '../utils/create-mui-theme';
import { createOverrides } from './overrides';

const CONFIG_TYPOGRAPHY = {
  BASE_FONT_SIZE: 16,
  RATIO_FACTOR: 1.15,
};

let THEME = createCustomMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 0,
      md: 0,
      lg: 601,
      xl: 601,
    },
  },

  mixins: {},

  palette: {
    primary: {
      main: colors.brand01[400],
    },
    secondary: {
      main: colors.brand02[400],
    },
    text: {
      primary: colors.neutral02,
      secondary: colors.neutral03[400],
    },
    background: {
      default: colors.neutral03[100],
    },
    info: {
      main: colors.brand01[400],
    },
    error: {
      main: colors.accent02[400],
    },
    success: {
      main: colors.accent01[400],
    },
    ...colors,
  },

  shadows: ([
    'none',
    ...new Array(24).fill('0px 1px 5px rgba(51, 51, 51, 0.05)'),
  ] as unknown) as Theme['shadows'], // why: https://github.com/mui-org/material-ui/issues/8209

  shape: {
    borderRadius: 4,
    radius: (factor) => 4 * factor,
  },

  spacing: 4,

  typography: {
    fontSize: CONFIG_TYPOGRAPHY.BASE_FONT_SIZE,
    htmlFontSize: CONFIG_TYPOGRAPHY.BASE_FONT_SIZE,

    fontFamily: ['TheSansLF', 'sans-serif'].join(','),

    h1: {
      fontSize: 20,
      lineHeight: '28px',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: 20,
      lineHeight: '28px',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 'bold',
    },
    h5: {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 'bold',
    },
    h6: {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 'bold',
    },
    body1: {
      fontSize: 16,
      lineHeight: '24px',
    },
    body2: {
      fontSize: 14,
      lineHeight: '24px',
    },
    subtitle1: {
      fontSize: 16,
      lineHeight: '24px',
    },
    subtitle2: {
      fontSize: 14,
      lineHeight: '18px',
    },
    caption: {
      fontSize: 14,
      lineHeight: '18px',
    },

    button: {
      fontWeight: 'normal',
    },
  },

  props: {
    MuiButtonBase: {
      disableRipple: true,
      disableTouchRipple: true,
    },
    MuiButton: {
      disableElevation: true,
    },
    MuiDialogContentText: {
      color: 'textPrimary',
    },
    MuiLink: {
      underline: 'always',
    },
    MuiSwitch: {
      color: 'primary',
      disableRipple: true,
    },
    MuiMenu: {
      disablePortal: true,
      keepMounted: true,
      getContentAnchorEl: null,
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      transformOrigin: { vertical: 'top', horizontal: 'left' },
    },
    MuiInput: {
      disableUnderline: true,
    },
    MuiInputAdornment: {
      disablePointerEvents: true,
    },
    MuiTextField: {
      InputProps: {
        disableUnderline: true,
      },
      InputLabelProps: {
        disableAnimation: true,
        shrink: true,
      },
    },
    MuiCheckbox: {
      icon: (
        <IcoCheckboxDefault
          title=""
          width={24}
          height={24}
          fill={colors.neutral03[200]}
        />
      ),
      checkedIcon: (
        <IcoCheckboxChecked
          title=""
          width={24}
          height={24}
          fill={colors.brand01[400]}
        />
      ),
    },
    MuiRadio: {
      icon: (
        <IcoRadioDefault
          title=""
          width={24}
          height={24}
          fill={colors.neutral03[200]}
        />
      ),
      checkedIcon: (
        <IcoRadioChecked
          title=""
          width={24}
          height={24}
          fill={colors.brand01[400]}
        />
      ),
    },
    MuiCircularProgress: {
      size: 28,
      thickness: 4,
    },
  },
});

THEME.overrides = createOverrides(THEME);

console.info('MUI theme created', THEME);

export default THEME;
