import React from 'react';
import { Box, DialogProps, DialogTitle } from '@material-ui/core';

import {
  ContainerStyled,
  PaperStyled,
  DialogContentStyled,
  PaperAfter,
} from './Page.style';

interface Props {
  scroll?: DialogProps['scroll']; // the whole setup is based on Dialog
  DialogTitleComponent?: React.ReactNode;
  AfterPaperComponent?: React.ReactNode;
}

const Page: React.FC<Props> = ({
  scroll = 'body',
  DialogTitleComponent,
  AfterPaperComponent,
  children,
}) => {
  const Parent =
    scroll === 'paper'
      ? {
          Component: Box,
          props: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'auto',
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
          },
        }
      : {
          Component: React.Fragment,
          props: {},
        };

  return (
    <Parent.Component {...Parent.props}>
      <ContainerStyled scroll={scroll} disableGutters>
        <PaperStyled scroll={scroll} hasAfter={Boolean(AfterPaperComponent)}>
          {DialogTitleComponent && (
            <DialogTitle disableTypography>{DialogTitleComponent}</DialogTitle>
          )}
          <DialogContentStyled
            dividers={scroll === 'paper' && Boolean(DialogTitleComponent)}
          >
            {children}
          </DialogContentStyled>
        </PaperStyled>
        {AfterPaperComponent && (
          <PaperAfter
            pl={{ xs: 6, lg: 0 }}
            pr={{ xs: 6, lg: 0 }}
            pt={scroll === 'paper' ? 4 : 6}
            pb={scroll === 'paper' ? 4 : 6}
          >
            {AfterPaperComponent}
          </PaperAfter>
        )}
      </ContainerStyled>
    </Parent.Component>
  );
};

export default Page;
