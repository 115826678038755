import { OktaAuth } from '@okta/okta-auth-js';
import { AxiosInstance } from 'axios';

import envService from 'config';
import RestApiService from './api';
import ErrorService from './error';
import { GAService } from './analytics';
import { messages } from './i18n/config';
import { createManager } from './api/axios';

/**
 * javascript / typescript don't support singletons
 * so we create a constant per service that contains our instance of this service
 * -
 * if you need to use a service somewhere (e.g. a saga) you can import it from here
 */
export let apiService: RestApiService<AxiosInstance>;
export let authService: OktaAuth;
export let errorService: ErrorService<typeof messages>;
export let gaService: GAService;
export { envService };
export * from './react-query';
/**
 * initialize services
 */
export function initServices() {
  console.info('initialize services');

  const apiManager = createManager({
    baseURL: envService.API_BASE,
    headers: { 'X-VanbredaAuthorizationCode': envService.VRB_AUTH_CODE },
  });

  apiService = new RestApiService<AxiosInstance>({ api: apiManager });

  authService = new OktaAuth({
    issuer: envService.IDP_ISSUER,
    clientId: envService.IDP_CLIENT_ID,
    redirectUri: `${window.location.origin}/callback`,
    responseType: 'code',
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    postLogoutRedirectUri: envService.IDP_BASE,
  });

  gaService = new GAService(envService.TRACK_GA_KEY);

  errorService = new ErrorService(messages);
}
