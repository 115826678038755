import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { apiService } from 'services';
import notificationSlice from 'store/notification/notification.slice';
import { getTranslation } from 'store/intl/intl.selectors';
import userSlice from 'store/user/user.slice';
import formEditPasswordSlice from './formEditPassword.slice';

function* submitForm({
  payload,
}: PayloadAction<{
  passwords: { passwordOld: string; passwordNew: string };
  onSuccess: () => void;
}>) {
  try {
    yield call([apiService, apiService.changePassword], payload.passwords);

    yield put(formEditPasswordSlice.actions.FORM_SUBMIT_SUCCESS());
    yield call([payload, payload.onSuccess]);

    yield put(userSlice.actions.SET_PASSWORD_UPDATED_DATE());

    const successMessage: ReturnType<typeof getTranslation> = yield select(
      getTranslation,
      'toast.messages.change_password.success',
    );
    yield put(
      notificationSlice.actions.NOTIFICATION_ADD({
        variant: 'success',
        content: successMessage,
      }),
    );
  } catch (e) {
    yield put(formEditPasswordSlice.actions.FORM_SUBMIT_FAIL());
  }
}

const formEditPasswordSaga = function* () {
  yield all([
    takeLatest(formEditPasswordSlice.actions.FORM_SUBMIT_START, submitForm),
  ]);
};

export default formEditPasswordSaga;
