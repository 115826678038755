import { Middleware } from 'redux';
import { createLogger } from 'redux-logger';
import { getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

export const sagaMiddleware = createSagaMiddleware();

const logger = createLogger({
  collapsed: true,
  level: 'info',
});

const devMiddlewares: Middleware[] = [sagaMiddleware];

if (process.env.NODE_ENV === 'development' && typeof window !== undefined) {
  devMiddlewares.push(logger);
}

export const middlewares = [
  ...getDefaultMiddleware({
    thunk: false,
    serializableCheck: {
      // redux persist does not pass the serializable check defined
      // Why should type be a string, or at least serializable:  https://redux.js.org/faq/actions/#why-should-type-be-a-string-or-at-least-serializable-why-should-my-action-types-be-constants
      // issue: https://github.com/rt2zz/redux-persist/issues/988
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
  ...devMiddlewares,
];
