import { all, call, put, takeLatest, SagaReturnType } from 'redux-saga/effects';

import { apiService, errorService } from 'services';
import applicationsSlice from './applications.slice';
import { ErrorTrace } from 'services/error/error.types';

export function* fetchApplications() {
  try {
    const response: SagaReturnType<typeof apiService.getApplications> = yield call(
      [apiService, apiService.getApplications],
    );

    const applications = response.data.data;

    if (applications.length) {
      yield put(
        applicationsSlice.actions.GET_APPLICATIONS_SUCCESS(applications),
      );
    } else {
      yield Promise.reject(
        errorService.create({
          message: 'Missing a valid set of applications.',
        }),
      );
    }
  } catch (e) {
    yield put(applicationsSlice.actions.GET_APPLICATIONS_FAIL(e as ErrorTrace));
  }
}

const applicationSaga = function* () {
  yield all([
    takeLatest(
      applicationsSlice.actions.GET_APPLICATIONS_START,
      fetchApplications,
    ),
  ]);
};

export default applicationSaga;
