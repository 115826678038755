import { Store } from 'store/reducers';

export const getIsActivationActive = (state: Store) =>
  state.activation.status === 'ACTIVE' && !state.activation.redirectUrl;

export const getIsActivationExpired = (state: Store) =>
  state.activation.status === 'EXPIRED';

export const getIsActivationLoading = (state: Store) =>
  state.activation.isLoading;

export const getIsActivationRenewing = (state: Store) =>
  state.activation.isRenewing;

export const getActivationTokenError = (state: Store) =>
  state.activation.tokenError;

export const getIsActivationCompleting = (state: Store) =>
  state.activation.isCompleting;

export const getRedirectUrl = (state: Store) =>
  state.activation.status === 'ACTIVE' && state.activation.redirectUrl
    ? state.activation.redirectUrl
    : undefined;
