import React from 'react';
import { Box, Typography, Link, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { isDev } from 'utils/env';
import { UserLanguage } from 'services/api/user.definitions';

interface Props {
  language?: UserLanguage;
  onEdit?: () => void;
}

const LanguageChoice: React.FC<Props> = ({
  language = isDev() ? 'EN' : undefined,
  onEdit = () => console.info('clicked edit'),
}) => (
  <section>
    <Box display="flex" justifyContent="space-between" mb={language ? 4 : 0}>
      <Typography component="h3" variant="h3" color="textPrimary">
        <FormattedMessage id="dashboard.profile.language_choice.title" />
      </Typography>
      <Link component="button" onClick={onEdit}>
        <FormattedMessage id="general.edit" />
      </Link>
    </Box>

    {language && (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box color="neutral03.300">
            <Typography color="inherit">
              <FormattedMessage id="form.language.label" />
            </Typography>
          </Box>
          <Typography>
            <FormattedMessage id={`language.${language.toLowerCase()}`} />
          </Typography>
        </Grid>
      </Grid>
    )}
  </section>
);

export default LanguageChoice;
