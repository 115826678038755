import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Typography } from '@material-ui/core';

import Page from 'components/@layout/Page';
import Logo from 'components/Logo';
import SetPasswordForm from './components/SetPasswordForm';
import {
  minChars,
  minLowercase,
  minUppercase,
  minNumbers,
} from './components/SetPasswordForm/SetPasswordForm.validation';

const ActivationSetPassword: React.FC = () => (
  <Page>
    <Box textAlign="center" width="100%" mb={{ xs: 7, lg: 10 }}>
      <Logo />
    </Box>
    <Box mb={6}>
      <Typography variant="h2" color="primary" gutterBottom>
        <FormattedMessage id="activation.set_password.title" />
      </Typography>
      <Typography color="textSecondary" gutterBottom>
        <FormattedMessage id="activation.set_password.description" />
      </Typography>
      <Box mb={2}>
        <Typography color="textSecondary">
          <strong>
            <FormattedMessage id="activation.set_password.rules.title" />
          </strong>
        </Typography>
      </Box>
      <Typography component="ul" color="textSecondary" gutterBottom>
        <li>
          <FormattedMessage
            id="activation.set_password.rules.min_chars"
            values={{ min: minChars }}
          />
        </li>
        <li>
          <FormattedMessage
            id="activation.set_password.rules.min_lowercase"
            values={{ min: minLowercase }}
          />
        </li>
        <li>
          <FormattedMessage
            id="activation.set_password.rules.min_uppercase"
            values={{ min: minUppercase }}
          />
        </li>
        <li>
          <FormattedMessage
            id="activation.set_password.rules.min_numbers"
            values={{ min: minNumbers }}
          />
        </li>
        <li>
          <FormattedMessage id="activation.set_password.rules.part_of_email" />
        </li>
      </Typography>
    </Box>

    <SetPasswordForm />
  </Page>
);

export default ActivationSetPassword;
