import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  UserCookies,
  CurrentUser,
  UpdateCurrentUserPayload,
} from 'services/api/user.definitions';
import { ErrorTrace } from 'services/error/error.types';

export interface UserState extends Partial<CurrentUser> {
  isLoading: boolean;
  error?: ErrorTrace;
  isUpdating: boolean;
}

const initialState: UserState = {
  isLoading: false,
  isUpdating: false,
  error: undefined,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    GET_USER_START: (state) => {
      state.isLoading = true;
      state.error = undefined;
    },
    GET_USER_SUCCESS: (state, action: PayloadAction<CurrentUser>) => ({
      ...state,
      isLoading: false,
      ...action.payload,
    }),
    GET_USER_FAIL: (state, action: PayloadAction<ErrorTrace>) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
    ACTIVATE_MFA: () => {},
    DEACTIVATE_MFA: () => {},
    RESET_MFA: () => {},
    UPDATE_USER_START: (
      state,
      _action: PayloadAction<
        Partial<UpdateCurrentUserPayload> & { onSuccess: () => void }
      >,
    ) => {
      state.isUpdating = true;
    },
    UPDATE_USER_SUCCESS: (state, action: PayloadAction<CurrentUser>) => ({
      ...state,
      isUpdating: false,
      ...action.payload,
    }),
    UPDATE_USER_FAIL: (state) => {
      state.isUpdating = false;
    },
    UNLINK_ITSME_START: (
      state,
      _action: PayloadAction<{
        email: string;
        password: string;
        onSuccess(): void;
      }>,
    ) => {
      state.isUpdating = true;
    },
    UNLINK_ITSME_SUCCESS: (state) => {
      state.isUpdating = false;
      state.itsmeReference = null;
    },
    UNLINK_ITSME_FAIL: (state) => {
      state.isUpdating = false;
    },
    SET_COOKIES_LOCAL: (
      state,
      action: PayloadAction<UserCookies | undefined>,
    ) => {
      state.cookies = action.payload
        ? {
            essential: action.payload.essential,
            analytics: action.payload.analytics,
          }
        : undefined;
    },
    SET_PASSWORD_UPDATED_DATE: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.passwordUpdatedDate = action.payload ?? new Date().toISOString();
    },
  },
});

export default userSlice;
