import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useField, ErrorMessage } from 'formik';

import HelperText from 'components/@common/HelperText';
import Password from 'components/@common/TextField/Password';

const FormPassword: React.FC<React.ComponentProps<typeof Password>> = ({
  name = '---DEFAULT_NAME---',
  type,
  ...rest
}) => {
  const [field, meta] = useField({ name, type });
  return (
    <Password
      {...field}
      type={type}
      error={Boolean(meta.error)}
      helperText={
        <ErrorMessage name={field.name}>
          {(
            msg: string | { id: string; values: { [key: string]: unknown } },
          ) => (
            <HelperText variant="error">
              <FormattedMessage
                {...(typeof msg === 'string'
                  ? { id: msg }
                  : { id: msg.id, values: msg.values })}
              />
            </HelperText>
          )}
        </ErrorMessage>
      }
      {...rest}
    />
  );
};

export default FormPassword;
