import {
  all,
  call,
  put,
  takeLatest,
  select,
  SagaReturnType,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { apiService, errorService, gaService } from 'services';
import QUERY_PARAMS from 'constants/query-params';
import { getQueryParams } from 'store/route/route.selectors';
import notificationSlice from 'store/notification/notification.slice';
import { getTranslation } from 'store/intl/intl.selectors';
import activationSlice from './activation.slice';
import { ErrorTrace } from 'services/error/error.types';

function* getActivation() {
  try {
    const params: SagaReturnType<typeof getQueryParams> = yield select(
      getQueryParams,
    );
    const token = params[QUERY_PARAMS.ACTIVATION_TOKEN];

    if (!token) {
      throw new Error(`Missing '${QUERY_PARAMS.ACTIVATION_TOKEN}'`);
    }

    const response: SagaReturnType<typeof apiService.getActivation> = yield call(
      [apiService, apiService.getActivation],
      token,
    );

    const status = response.data.status;

    if (status === 'ACTIVE') {
      yield call([gaService, gaService.trackActivationActive]);
      yield put(activationSlice.actions.GET_ACTIVATION_SUCCESS({ status }));
    } else if (status === 'EXPIRED') {
      yield call([gaService, gaService.trackActivationExpired]);
      yield put(activationSlice.actions.GET_ACTIVATION_SUCCESS({ status }));
    } else {
      // custom error if any critical info is missing
      yield Promise.reject(
        errorService.create({
          message: 'Missing critical information to activate account',
        }),
      );
    }
  } catch (e) {
    yield call([gaService, gaService.trackActivationInvalid]);
    yield put(activationSlice.actions.GET_ACTIVATION_FAIL(e as ErrorTrace));
  }
}

function* renewActivation() {
  try {
    const params: SagaReturnType<typeof getQueryParams> = yield select(
      getQueryParams,
    );
    const token = params[QUERY_PARAMS.ACTIVATION_TOKEN];

    if (!token) {
      throw new Error(`Missing '${QUERY_PARAMS.ACTIVATION_TOKEN}'`);
    }

    yield call([apiService, apiService.renewActivation], token);
    yield call([gaService, gaService.trackActivationRenewed]);

    yield put(activationSlice.actions.RENEW_ACTIVATION_COMPLETED());

    const successMessage: ReturnType<typeof getTranslation> = yield select(
      getTranslation,
      'toast.messages.activation.renew.success',
    );
    yield put(
      notificationSlice.actions.NOTIFICATION_ADD({
        variant: 'success',
        content: successMessage,
      }),
    );
  } catch (e) {
    yield put(activationSlice.actions.RENEW_ACTIVATION_COMPLETED());
  }
}

function* completeActivation({
  payload,
}: PayloadAction<{ newPassword: string }>) {
  try {
    const params: SagaReturnType<typeof getQueryParams> = yield select(
      getQueryParams,
    );
    const token = params[QUERY_PARAMS.ACTIVATION_TOKEN];

    if (!token) {
      throw new Error(`Missing '${QUERY_PARAMS.ACTIVATION_TOKEN}'`);
    }

    const response: SagaReturnType<typeof apiService.completeActivation> = yield call(
      [apiService, apiService.completeActivation],
      token,
      payload.newPassword,
    );

    const redirectUrl = response.data.redirectUrl;

    if (!redirectUrl) {
      yield Promise.reject(
        errorService.create({
          message:
            'Missing critical information to complete account activation',
        }),
      );
    }

    yield call([gaService, gaService.trackActivationCompleted]);

    yield put(
      activationSlice.actions.COMPLETE_ACTIVATION_SUCCESS({
        redirectUrl: redirectUrl,
      }),
    );
  } catch (e) {
    yield put(activationSlice.actions.COMPLETE_ACTIVATION_FAIL());
  }
}

const activationSaga = function* () {
  yield all([
    takeLatest(
      activationSlice.actions.GET_ACTIVATION_START, //
      getActivation,
    ),
    takeLatest(
      activationSlice.actions.RENEW_ACTIVATION_START, //
      renewActivation,
    ),
    takeLatest(
      activationSlice.actions.COMPLETE_ACTIVATION_START,
      completeActivation,
    ),
  ]);
};

export default activationSaga;
