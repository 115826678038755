import React from 'react';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';

import { messages } from 'services/i18n/config';
import { getLocale } from 'store/intl/intl.selectors';

const intlConnector = (C: React.StatelessComponent) => {
  const Intl: React.FC = (props) => {
    const locale = useSelector(getLocale);

    return (
      <IntlProvider locale={locale} messages={messages[locale]}>
        <C {...props} />
      </IntlProvider>
    );
  };

  return Intl;
};

export default intlConnector;
