import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, TextField, TextFieldProps, Link } from '@material-ui/core';

const PasswordLabel: React.FC<{
  showPassword: boolean;
  onClickShowPassword: (e: React.MouseEvent<HTMLElement>) => void;
}> = ({ showPassword, onClickShowPassword, children }) => {
  return (
    <Box component="span" display="flex" justifyContent="space-between">
      <Box component="span" mr={4}>
        {children}
      </Box>
      <Box clone fontWeight="normal" fontSize={14}>
        <Link component="span" onClick={onClickShowPassword}>
          <FormattedMessage
            id={
              showPassword
                ? 'input.toggle_password.hide'
                : 'input.toggle_password.show'
            }
          />
        </Link>
      </Box>
    </Box>
  );
};

interface PasswordState {
  showPassword: boolean;
}

const Password: React.FC<TextFieldProps> = ({ label, ...rest }) => {
  const [values, setValues] = React.useState<PasswordState>({
    showPassword: false,
  });

  const handleClickShowPassword = (e: any) => {
    e.preventDefault(); // to stop focussing the input on click
    setValues({ ...values, showPassword: !values.showPassword });
  };

  return (
    <TextField
      {...rest}
      label={
        <PasswordLabel
          showPassword={values.showPassword}
          onClickShowPassword={handleClickShowPassword}
        >
          {label}
        </PasswordLabel>
      }
      type={values.showPassword ? 'text' : 'password'}
    />
  );
};

export default Password;
