import React from 'react';

interface Props {}

const Boundary = (
  BoundaryComponent: React.ComponentType, //
) => (
  Component: any, //
) => (
  props: Props, //
) => (
  <BoundaryComponent>
    <Component {...props} />
  </BoundaryComponent>
);

export default Boundary;
