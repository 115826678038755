enum QUERY_PARAMS {
  CLIENT_ID = 'client_id',
  FORCE_LANGUAGE = 'force_language',
  DEFAULT_FIRSTNAME = 'default_firstname',
  DEFAULT_LASTNAME = 'default_lastname',
  DEFAULT_EMAIL = 'default_email',
  SHOW_LOGIN_LINK = 'show_login_link',
  ACTIVATION_TOKEN = 'activation_token',
  ONBOARDING_TOKEN = 'onboarding_token',
  ANALYTICS_CONSENT_GIVEN = 'analytics_consent_given',
  WEBVIEW_CLOSE_URI = 'webview_close_uri',
  ITSME_CODE = 'code',
  STATE = 'state',
  SHOW_OPTIONS = 'show_options',
}

export default QUERY_PARAMS;
