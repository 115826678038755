import { combineReducers } from 'redux';

import system from './system/system.slice';
import intl from './intl/intl.slice';
import register from './register/register.slice';
import user from './user/user.slice';
import activation from './activation/activation.slice';
import applications from './applications/applications.slice';
import notification from './notification/notification.slice';
import formEditPassword from './form-edit-password/formEditPassword.slice';

export const reducers = {
  system: system.reducer,
  applications: applications.reducer,
  intl: intl.reducer,
  register: register.reducer,
  user: user.reducer,
  activation: activation.reducer,
  notification: notification.reducer,
  formEditPassword: formEditPassword.reducer,
};

export const rootReducer = combineReducers(reducers);

export type RootReducer = typeof rootReducer;
export type Store = ReturnType<RootReducer>;

export default rootReducer;
