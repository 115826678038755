import React from 'react';
import { Link } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

import { Routes } from 'pages/routes.constants';
import ErrorPage from 'components/@layout/ErrorPage';

const NotFound = () => (
  <ErrorPage
    code={404}
    title={<FormattedMessage id="page.error.404.title" />}
    description={
      <FormattedMessage
        id="page.error.404.description"
        values={{
          a: (t: string) => (
            <Link component={RouterLink} to={Routes.Root}>
              {t}
            </Link>
          ),
        }}
      />
    }
    children={null}
  />
);

export default NotFound;
