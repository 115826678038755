import React from 'react';
import { Box, Typography, DialogProps } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import Page from 'components/@layout/Page';
import Logo from 'components/Logo';
import { DialogStyled } from './ConsentOverlay.style';

const ConsentOverlay: React.FC<Pick<DialogProps, 'open'>> = ({
  open = true,
  children,
}) => (
  <DialogStyled
    aria-labelledby="consent"
    scroll="body"
    fullScreen
    open={open}
    disableBackdropClick
    disableEscapeKeyDown
    disablePortal
    keepMounted={false}
    TransitionProps={{ enter: false, unmountOnExit: true }}
    transitionDuration={{ appear: 0 }}
  >
    <Page>
      <Box textAlign="center" width="100%" mb={{ xs: 7, lg: 10 }}>
        <Logo />
      </Box>

      <Box mb={10}>
        <Typography variant="h2" color="primary" gutterBottom>
          <FormattedMessage id="consent.title" />
        </Typography>

        <Typography color="textSecondary">
          <FormattedMessage id="consent.description" />
        </Typography>
      </Box>

      {children}
    </Page>
  </DialogStyled>
);

export default ConsentOverlay;
