type Language = {
  label: string;
  code: string;
};

// based on `services/i18n/config` locales

const options: Language[] = [
  {
    label: 'language.nl',
    code: 'nl',
  },
  {
    label: 'language.fr',
    code: 'fr',
  },
  {
    label: 'language.en',
    code: 'en',
  },
];

export default options;
