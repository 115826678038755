import axios, { AxiosRequestConfig } from 'axios';
import humps from 'humps';
import _merge from 'lodash/merge';

import store from 'store';
import { authService, errorService } from 'services';
import { getLocale } from 'store/intl/intl.selectors';
import { getClientId } from 'store/system/system.selectors';
import notificationSlice from 'store/notification/notification.slice';

const DEFAULT_CONFIG: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
  transformRequest: [
    (data) => {
      if (!(data instanceof FormData)) {
        // convert all body keys to snake_case
        data = humps.decamelizeKeys(data);

        // convert all body objects to strings
        if (typeof data === 'object') {
          data = JSON.stringify(data);
        }
      }

      return data;
    },
  ],
  transformResponse: [
    (data) => {
      try {
        // convert all body keys to camelCase
        if (typeof data !== 'object' && data) {
          data = JSON.parse(data);
        }
        if (!(data instanceof Blob)) {
          data = humps.camelizeKeys(data, function (key, convert) {
            // https://github.com/domchristie/humps/blob/master/README.md#converting-object-keys
            // prevent humps for camelizing uppercase keys
            return /^[A-Z0-9_]+$/.test(key) ? key : convert(key);
          });
        }
        return data;
      } catch (error) {
        return humps.camelizeKeys(data);
      }
    },
  ],
};

const createManager = (config?: AxiosRequestConfig) => {
  const manager = axios.create(_merge(DEFAULT_CONFIG, config));

  manager.interceptors.request.use(
    async (axiosConfig) => {
      const state = store.store.getState();

      const locale = getLocale(state);
      axiosConfig.headers['Accept-Language'] = locale.toUpperCase();

      const isPrivateEndpoint = axiosConfig.url?.includes('/api/');
      if (isPrivateEndpoint) {
        const token = authService.getAccessToken();
        axiosConfig.headers['Authorization'] = `Bearer ${token}`;
      }

      const isPublicEndpoint = axiosConfig.url?.includes('/public_api/');
      if (isPublicEndpoint) {
        const clientId = getClientId(state);
        axiosConfig.headers['X-Client-Id'] = clientId;
      }

      return axiosConfig;
    },
    (error) => Promise.reject(error),
  );

  manager.interceptors.response.use(
    async (axiosConfig) => axiosConfig,
    (error) => {
      const httpError = errorService.createHttp(error);

      const errorKeys: string[] | undefined = error.response?.data.errors;
      if (errorKeys) {
        errorKeys.forEach((key) => {
          store.store.dispatch(
            notificationSlice.actions.NOTIFICATION_ADD({
              variant: 'error',
              content:
                errorService.translateHttpKey(
                  key,
                  error.response?.config.headers['Accept-Language'] ?? '',
                ) ??
                httpError.message ??
                key,
            }),
          );
        });
      } else if (httpError.code !== 409) {
        store.store.dispatch(
          notificationSlice.actions.NOTIFICATION_ADD({
            variant: 'error',
            content: httpError.message,
          }),
        );
      }

      return Promise.reject(httpError);
    },
  );

  // manager.interceptors.request.use(
  //   async axiosConfig => {
  //     const isMockingEnabled = config.MOCK_API_BASE && config.MOCK_URLS.length;
  //     if (isMockingEnabled) {
  //       for (let i = 0; i < config.MOCK_URLS.length; i++) {
  //         if (axiosConfig.url && config.MOCK_URLS[i].test(axiosConfig.url)) {
  //           console.info('[HTTP]: now mocking url', axiosConfig.url);
  //           axiosConfig.baseURL = config.MOCK_API_BASE;
  //           break;
  //         }
  //       }
  //     }

  //     return axiosConfig;
  //   },
  //   error => {
  //     return Promise.reject(error);
  //   },
  // );

  return manager;
};

export { createManager };
