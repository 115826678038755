// comment out locales that should not be supported
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/dist/locale-data/be';
import '@formatjs/intl-relativetimeformat/dist/locale-data/fr';

import MESSAGES_EN from './en.json';
import MESSAGES_NL from './nl.json';
import MESSAGES_FR from './fr.json';

const messages: { [key: string]: { [key: string]: string } } = {
  en: MESSAGES_EN,
  nl: MESSAGES_NL,
  fr: MESSAGES_FR,
};

const locales = Object.keys(messages);

const isValidLocale = (locale: string) => locales.includes(locale);

export { locales, messages, isValidLocale };
