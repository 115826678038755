import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Container, Box, CircularProgress } from '@material-ui/core';

import Pages from 'pages';
import { isSystemBooting, getSystemError } from 'store/system/system.selectors';
import ErrorPage from 'components/@layout/ErrorPage';
import withConnectors from './connectors';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'services';

const App: React.FC = () => {
  const intl = useIntl();

  const loading = useSelector(isSystemBooting);
  const error = useSelector(getSystemError);

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={null}>
        <Helmet>
          <title>{intl.formatMessage({ id: 'page.general.title' })}</title>
          <meta
            name="description"
            content={intl.formatMessage({ id: 'page.general.description' })}
          />
        </Helmet>

        {loading ? (
          <Container fixed>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              <CircularProgress size={40} />
            </Box>
          </Container>
        ) : Boolean(error) ? (
          <ErrorPage code={error?.code} />
        ) : (
          <Pages />
        )}
      </Suspense>
    </QueryClientProvider>
  );
};

export default withConnectors(App);
