import React, { useState } from 'react';
import { Box, Divider } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { getUser } from 'store/user/user.selectors';
import Page from 'components/@layout/Page';
import Title from './components/Title';
import ProfileData from './components/ProfileData';
import LanguageChoice from './components/LanguageChoice';
import ItsmeSettingsEdit from './components/ItsmeSettingsEdit';

import EditProfile from './edit-profile';
import EditLanguage from './edit-language';
import ItsmeSettings from './itsme-settings';

const DashboardProfile: React.FC = () => {
  const user = useSelector(getUser);

  const hasItsmeLinked = !!user.itsmeReference;

  /**
   * we don't want seperate routes for these because the user shouldn't be able
   * to navigate to it directly so to handle this we use local state
   */

  // PROFILE
  const [editProfile, setEditProfile] = useState(false);

  const handleEditProfile = () => {
    setEditProfile(true);
  };

  const handleEditProfileClose = () => {
    setEditProfile(false);
  };

  // LANGUAGE
  const [editLanguage, setEditLanguage] = useState(false);

  const handleEditLanguage = () => {
    setEditLanguage(true);
  };

  const handleEditLanguageClose = () => {
    setEditLanguage(false);
  };

  // ITSME
  const [itsmeSettings, setItsmeSettings] = useState(false);

  const handleItsmeSettings = () => {
    setItsmeSettings(true);
  };

  const handleItsmeSettingsClose = () => {
    setItsmeSettings(false);
  };

  if (editProfile) {
    return (
      <EditProfile
        onBack={handleEditProfileClose}
        onSuccess={handleEditProfileClose}
      />
    );
  } else if (editLanguage) {
    return (
      <EditLanguage
        onBack={handleEditLanguageClose}
        onSuccess={handleEditLanguageClose}
      />
    );
  } else if (itsmeSettings && hasItsmeLinked) {
    return (
      <ItsmeSettings
        onBack={handleItsmeSettingsClose}
        onSuccess={handleItsmeSettingsClose}
      />
    );
  }

  return (
    <Page scroll="paper" DialogTitleComponent={<Title />}>
      <ProfileData
        firstName={user.firstName}
        lastName={user.lastName}
        email={user.email}
        onEdit={handleEditProfile}
      />

      <Box mt={6} mb={6}>
        <Divider />
      </Box>

      <LanguageChoice language={user.language} onEdit={handleEditLanguage} />

      {hasItsmeLinked && (
        <>
          <Box mt={6} mb={6}>
            <Divider />
          </Box>

          <ItsmeSettingsEdit onEdit={handleItsmeSettings} />
        </>
      )}
    </Page>
  );
};

export default DashboardProfile;
