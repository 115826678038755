import { AxiosRequestConfig, AxiosResponse } from 'axios';

export type ErrorTrace<T = { [key: string]: unknown }> = {
  code?: number | string;
  errors?: string[];
  statusCode?: number;
  message?: string | string[];
  stack?: string;
  custom?: T;
};

export type CustomHttp = {
  config: AxiosRequestConfig;
  response?: AxiosResponse;
};

export class UnknownOriginInState extends Error {
  constructor() {
    super('Unknown origin in state');
    this.name = 'UnknownOriginInState';
  }
}

export class MissingStateInSearchParams extends Error {
  constructor() {
    super('Missing state in search params');
    this.name = 'MissingStateInSearchParams';
  }
}

export class MissingOriginInState extends Error {
  constructor() {
    super('Missing origin in state');
    this.name = 'MissingOriginInState';
  }
}
