import { Store } from 'store/reducers';

export const getUser = (state: Store) => state.user;
export const getUserCookies = (state: Store) => state.user.cookies;
export const getIsUserLoading = (state: Store) => state.user.isLoading;
export const getIsUserUpdating = (state: Store) => state.user.isUpdating;
export const getUserError = (state: Store) => state.user.error;

export const canTrackUser = (state: Store) =>
  state.user.cookies?.analytics ?? false;

export const getUserApplications = (state: Store) => {
  const applications = state.applications.data;
  const userApplicationIds = state.user.activeApplicationIds ?? [];
  return applications?.filter((application) =>
    userApplicationIds.includes(application.id),
  );
};
