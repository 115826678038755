import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RegisterValues } from 'pages/register/components/RegisterForm/RegisterForm.types';
import { ErrorTrace } from 'services/error/error.types';

export interface RegisterState {
  isSubmittingForm: boolean;
  registeredEmail?: string;
  error?: ErrorTrace;
  activationToken?: string;
}

const initialState: RegisterState = {
  isSubmittingForm: false,
  registeredEmail: undefined,
  error: undefined,
  activationToken: undefined,
};

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    FORM_RESET: (state) => {
      state.registeredEmail = undefined;
    },
    FORM_SUBMIT_START: (
      state,
      _action: PayloadAction<{
        formValues: RegisterValues;
      }>,
    ) => {
      state.isSubmittingForm = true;
      state.registeredEmail = undefined;
      state.error = undefined;
      state.activationToken = undefined;
    },
    FORM_SUBMIT_SUCCESS: (
      state,
      action: PayloadAction<{ email: string; token?: string }>,
    ) => {
      state.isSubmittingForm = false;
      state.registeredEmail = action.payload.email;
      state.error = undefined;
      state.activationToken = action.payload.token;
    },
    FORM_SUBMIT_FAIL: (state, action: PayloadAction<ErrorTrace>) => {
      state.isSubmittingForm = false;
      state.registeredEmail = undefined;
      state.error = action.payload;
      state.activationToken = undefined;
    },
  },
});

export default registerSlice;
