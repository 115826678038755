import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography, Box, Link } from '@material-ui/core';

import { Routes } from 'pages/routes.constants';
import Page from 'components/@layout/Page';
import Logo from 'components/Logo';

interface Props {
  title?: React.ReactNode;
  description?: React.ReactNode;
  code?: string | number;
}

const ErrorPage: React.FC<Props> = ({
  title = <FormattedMessage id="page.error.default.title" />,
  description = <FormattedMessage id="page.error.default.description" />,
  code,
  children = (
    <Link href={Routes.Root}>
      <FormattedMessage id="page.error.default.action" />
    </Link>
  ),
}) => (
  <Page scroll="paper">
    <Box mb={4}>
      <Box textAlign="center" width="100%" mb={{ xs: 7, lg: 10 }}>
        <Logo title="Vanbreda Risk & Benefits" />
      </Box>

      <Typography variant="h1">{title}</Typography>

      {code && (
        <Typography color="textSecondary">
          <FormattedMessage
            id="page.general.error_code"
            values={{ code: <b>{code}</b> }}
          />
        </Typography>
      )}
    </Box>

    <Typography color="textPrimary" gutterBottom>
      {description}
    </Typography>

    {children}
  </Page>
);

export default ErrorPage;
