import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OptionsObject, SnackbarKey } from 'notistack';

export type NotificationState = {
  items: OptionsObject[];
};

const initialState: NotificationState = {
  items: [],
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    NOTIFICATION_ADD: (state, action: PayloadAction<OptionsObject>) => {
      state.items = [
        ...state.items,
        {
          ...action.payload,
          key: action.payload.key || new Date().getTime() + Math.random(),
        },
      ];
    },
    NOTIFICATION_REMOVE: (
      state,
      action: PayloadAction<SnackbarKey | undefined>,
    ) => {
      state.items = state.items.filter(
        (notification) => notification.key !== action.payload,
      );
    },
  },
});

export default notificationSlice;
