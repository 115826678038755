import React from 'react';
import ConsentForm from 'pages/shared/Consent/components/ConsentForm/ConsentForm';

interface Props {
  isSubmitting: boolean;
}

const EditConsentForm: React.FC<Props> = ({ isSubmitting }) => (
  <ConsentForm isSubmitting={isSubmitting} />
);

export default EditConsentForm;
