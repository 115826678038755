import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Box, Typography } from '@material-ui/core';
import { useOktaAuth } from '@okta/okta-react';
import { useSelector } from 'react-redux';

import { getUser } from 'store/user/user.selectors';
import Page from 'components/@layout/Page';
import Logo from 'components/Logo';
import MyProducts from './components/MyProducts';
import MyData from './components/MyData';

const DashboardOverview: React.FC<RouteComponentProps> = () => {
  const user = useSelector(getUser);
  const { oktaAuth } = useOktaAuth();

  const handleLogout = async () => {
    await oktaAuth.signOut();
  };

  return (
    <Page scroll="paper">
      <Box textAlign="center" width="100%" mb={{ xs: 7, lg: 10 }}>
        <Logo />
      </Box>

      <Box clone color="neutral03.300">
        <Typography variant="h2">
          <FormattedMessage
            id="dashboard.overview.title"
            values={{ name: user.firstName }}
          />
        </Typography>
      </Box>

      <Box mb={10}>
        <MyProducts />
      </Box>

      <MyData onLogout={handleLogout} />
    </Page>
  );
};

export default DashboardOverview;
