import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
} from '@material-ui/core';
import { useField } from 'formik';

import HelperText from 'components/@common/HelperText';

const FormCheckbox: React.FC<
  CheckboxProps & Partial<Pick<FormControlLabelProps, 'label'>>
> = ({ name = '---DEFAULT_NAME---', label, ...rest }) => {
  const [field, meta] = useField({ name, type: 'checkbox' });
  const CheckboxField = <Checkbox {...field} {...rest} />;

  return (
    <>
      {label ? (
        <FormControlLabel label={label} control={CheckboxField} />
      ) : (
        CheckboxField
      )}
      {Boolean(meta.error) && meta.touched && (
        <HelperText variant="error">
          <FormattedMessage id={meta.error} />
        </HelperText>
      )}
    </>
  );
};

export default FormCheckbox;
