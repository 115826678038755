import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Typography, Button } from '@material-ui/core';

import Page from 'components/@layout/Page';
import Logo from 'components/Logo';

interface Props {
  redirectUrl: string;
}

const ActivationConfirm: React.FC<Props> = ({ redirectUrl }) => (
  <Page>
    <Box textAlign="center" width="100%" mb={{ xs: 7, lg: 10 }}>
      <Logo />
    </Box>
    <Typography variant="h2" color="primary" gutterBottom>
      <FormattedMessage id="activation.confirm.title" />
    </Typography>
    <Box mb={6}>
      <Typography color="textSecondary" gutterBottom>
        <FormattedMessage id="activation.confirm.description" />
      </Typography>
    </Box>
    <Button
      component="a"
      href={redirectUrl}
      variant="contained"
      color="primary"
      fullWidth
    >
      <FormattedMessage id="activation.confirm.button" />
    </Button>
  </Page>
);

export default ActivationConfirm;
