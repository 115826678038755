import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface FormEditPasswordState {
  isSubmitting: boolean;
}

const initialState: FormEditPasswordState = {
  isSubmitting: false,
};

const formEditPasswordSlice = createSlice({
  name: 'form-edit-password',
  initialState,
  reducers: {
    FORM_SUBMIT_START: (
      state,
      _payload: PayloadAction<{
        passwords: { passwordOld: string; passwordNew: string };
        onSuccess: () => void;
      }>,
    ) => {
      state.isSubmitting = true;
    },
    FORM_SUBMIT_SUCCESS: (state) => {
      state.isSubmitting = false;
    },
    FORM_SUBMIT_FAIL: (state) => {
      state.isSubmitting = false;
    },
  },
});

export default formEditPasswordSlice;
