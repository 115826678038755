import { useMutation } from '@tanstack/react-query';
import { apiService } from 'services';
import { UpdateUserCredentialsPayload } from 'services/api/user.definitions';

export const updateUserCredentials = async (
  body: UpdateUserCredentialsPayload,
) => apiService.updateUserCredentials(body);

export const useUpdateUserCredentials = () => {
  const { mutate, isLoading } = useMutation({
    mutationFn: updateUserCredentials,
  });

  return {
    updateUserCredentials: mutate,
    isLoading,
  };
};
