import * as Yup from 'yup';
import { FIELD_NAME } from './SetPasswordForm.constants';

/**
 * validation rules are hardcoded for now but mimic the okta settings
 */

export const minChars = 8;
export const minLowercase = 1;
export const minUppercase = 1;
export const minNumbers = 1;

const containsLowerCase = (value: string = '') => /.*[a-z].*/.test(value);
const containsUpperCase = (value: string = '') => /.*[A-Z].*/.test(value);
const containsNumber = (value: string = '') => /.*[0-9].*/.test(value);

export const ValidationSchema = Yup.object().shape({
  [FIELD_NAME.PASSWORD]: Yup.string()
    .min(minChars, (values) => ({ id: 'form.error.string.min_chars', values }))
    .test(
      'lowercase',
      () => ({
        id: 'form.error.string.min_lowercase',
        values: { min: minLowercase },
      }),
      containsLowerCase,
    )
    .test(
      'uppercase',
      () => ({
        id: 'form.error.string.min_uppercase',
        values: { min: minUppercase },
      }),
      containsUpperCase,
    )
    .test(
      'number',
      () => ({
        id: 'form.error.string.min_number',
        values: { min: minNumbers },
      }),
      containsNumber,
    )
    .required('form.error.required'),
  [FIELD_NAME.PASSWORD_CONFIRM]: Yup.string()
    .oneOf([Yup.ref(FIELD_NAME.PASSWORD), null], 'form.error.password.mismatch')
    .required('form.error.required'),
});
