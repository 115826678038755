import React from 'react';
import Page from 'components/@layout/Page';
import { Wrap, Title } from '../styles';
import { Box, Button, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import envService from 'config';
import { DecodedState } from 'types/state.types';
import { decodeState } from 'utils/okta.utils';
import {
  MissingOriginInState,
  MissingStateInSearchParams,
} from 'services/error/error.types';

const SuccessfullyLinked = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const state = urlParams.get('state') ?? '';

  const handleRedirectToLogin = () => {
    const decodedState = decodeState<DecodedState>(state);

    if (!decodedState) {
      throw new MissingStateInSearchParams();
    }

    if (!decodedState.origin) {
      throw new MissingOriginInState();
    }

    const redirectUrl = envService.getCallbackItsmeBaseUrl(decodedState.origin);
    window.location.replace(redirectUrl);
  };

  return (
    <Page scroll="paper">
      <Wrap>
        <Box flex={1}>
          <Title variant="h1">
            <FormattedMessage id="itsme.succesfully_linked.title" />
          </Title>
          <Typography>
            <FormattedMessage
              id="itsme.succesfully_linked.description"
              values={{
                b: (chunks: string) => <strong>{chunks}</strong>,
              }}
            />
          </Typography>
        </Box>
        <Box>
          <Button
            onClick={handleRedirectToLogin}
            fullWidth
            variant="contained"
            color="primary"
          >
            <FormattedMessage id="ota.confirm.button.connect" />
          </Button>
        </Box>
      </Wrap>
    </Page>
  );
};

export default SuccessfullyLinked;
