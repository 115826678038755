import * as Yup from 'yup';
import { FIELD_NAME } from './constants';

export const ValidationSchema = Yup.object().shape({
  [FIELD_NAME.EMAIL]: Yup.string()
    .email('form.error.email')
    .required('form.error.required'),
  [FIELD_NAME.PASSWORD]: Yup.string().required('form.error.required'),
  [FIELD_NAME.PASSWORD_NEW_CONFIRM]: Yup.string()
    .oneOf([Yup.ref(FIELD_NAME.PASSWORD), null], 'form.error.password.mismatch')
    .required('form.error.required'),
});
