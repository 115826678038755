import {
  all,
  call,
  put,
  takeLatest,
  select,
  SagaReturnType,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { apiService, authService } from 'services';
import { UpdateCurrentUserPayload } from 'services/api/user.definitions';
import notificationSlice from 'store/notification/notification.slice';
import { setUserLocale } from 'store/intl/intl.saga';
import { getTranslation } from 'store/intl/intl.selectors';
import { verifyAnalyticsTracking } from 'store/system/system.saga';
import userSlice from './user.slice';
import { getUser } from './user.selectors';
import { ErrorTrace } from 'services/error/error.types';

export function* fetchUser() {
  try {
    const response: SagaReturnType<typeof apiService.getUser> = yield call([
      apiService,
      apiService.getUser,
    ]);
    yield put(userSlice.actions.GET_USER_SUCCESS(response.data));
    return response;
  } catch (e) {
    yield put(userSlice.actions.GET_USER_FAIL(e as ErrorTrace));
  }
}

function* activateMFA() {
  try {
    yield call([apiService, apiService.activateMFA]);
    yield call([authService, authService.signOut]);
  } catch (e) {}
}

function* deactivateMFA() {
  try {
    yield call([apiService, apiService.deactivateMFA]);
    yield call([authService, authService.signOut]);
  } catch (e) {}
}

function* resetMFA() {
  try {
    yield call([apiService, apiService.resetMFA]);
    yield call([authService, authService.signOut]);
  } catch (e) {}
}

function* updateUser({
  payload,
}: PayloadAction<
  Partial<UpdateCurrentUserPayload> & { onSuccess: () => void }
>) {
  try {
    const user: ReturnType<typeof getUser> = yield select(getUser);
    const response: SagaReturnType<typeof apiService.updateUser> = yield call(
      [apiService, apiService.updateUser],
      {
        firstName: payload.firstName ?? user.firstName ?? '',
        lastName: payload.lastName ?? user.lastName ?? '',
        email: payload.email ?? user.email ?? '',
        language: payload.language ?? user.language,
        cookies: payload.cookies ??
          user.cookies ?? { essential: true, analytics: false },
      },
    );

    yield put(userSlice.actions.UPDATE_USER_SUCCESS(response.data));
    yield call(setUserLocale);

    yield call([payload, payload.onSuccess]);

    const successMessage: ReturnType<typeof getTranslation> = yield select(
      getTranslation,
      'toast.messages.update_user.success',
    );
    yield put(
      notificationSlice.actions.NOTIFICATION_ADD({
        variant: 'success',
        content: successMessage,
      }),
    );
  } catch (e) {
    yield put(userSlice.actions.UPDATE_USER_FAIL());
  }
}

function* unlinkItsme({
  payload,
}: ReturnType<typeof userSlice.actions.UNLINK_ITSME_START>) {
  try {
    // First we update the user email
    const user: ReturnType<typeof getUser> = yield select(getUser);
    yield call([apiService, apiService.updateUser], {
      firstName: user.firstName ?? '',
      lastName: user.lastName ?? '',
      email: payload.email ?? user.email ?? '',
      language: user.language,
      cookies: user.cookies ?? { essential: true, analytics: false },
    });

    // Next we update the user credentials to type "OKTA"
    yield call([apiService, apiService.updateUserCredentials], {
      type: 'OKTA',
      password: payload.password,
    });

    // Finally we update the itsme reference on the user to complete the unlinking
    yield call([apiService, apiService.partialUpdateUser], {
      itsmeReference: null,
    });

    yield put(userSlice.actions.UNLINK_ITSME_SUCCESS());

    yield call([payload, payload.onSuccess]);

    const successMessage: ReturnType<typeof getTranslation> = yield select(
      getTranslation,
      'toast.messages.itsme_unlink.success',
    );
    yield put(
      notificationSlice.actions.NOTIFICATION_ADD({
        variant: 'success',
        content: successMessage,
      }),
    );
  } catch (e) {
    yield put(userSlice.actions.UNLINK_ITSME_FAIL());

    const errorMessage: ReturnType<typeof getTranslation> = yield select(
      getTranslation,
      'toast.messages.itsme_unlink.fail',
    );
    yield put(
      notificationSlice.actions.NOTIFICATION_ADD({
        variant: 'error',
        content: errorMessage,
      }),
    );
  }
}

export default function* watchUser() {
  yield all([
    takeLatest(userSlice.actions.GET_USER_START, fetchUser),
    takeLatest(userSlice.actions.GET_USER_SUCCESS, setUserLocale),
    takeLatest(userSlice.actions.ACTIVATE_MFA, activateMFA),
    takeLatest(userSlice.actions.DEACTIVATE_MFA, deactivateMFA),
    takeLatest(userSlice.actions.RESET_MFA, resetMFA),
    takeLatest(userSlice.actions.UPDATE_USER_START, updateUser),
    takeLatest(userSlice.actions.UNLINK_ITSME_START, unlinkItsme),
    takeLatest(userSlice.actions.UPDATE_USER_SUCCESS, verifyAnalyticsTracking),
  ]);
}
