import ReactGA, { InitializeOptions } from 'react-ga';

class GAService {
  public key: string;

  constructor(key: string, options?: InitializeOptions) {
    this.key = key;
    ReactGA.initialize(key, options);
  }

  enable() {
    // @ts-ignore
    window[`ga-disable-${this.key}`] = false;
  }

  disable() {
    // @ts-ignore
    window[`ga-disable-${this.key}`] = true;
  }

  trackActivationActive() {
    ReactGA.event({
      category: 'Activation',
      action: 'Active',
    });
  }

  trackActivationExpired() {
    ReactGA.event({
      category: 'Activation',
      action: 'Expired',
    });
  }

  trackActivationInvalid() {
    ReactGA.event({
      category: 'Activation',
      action: 'Invalid',
    });
  }

  trackActivationRenewed() {
    ReactGA.event({
      category: 'Activation',
      action: 'Renewed',
    });
  }

  trackActivationCompleted() {
    ReactGA.event({
      category: 'Activation',
      action: 'Completed',
    });
  }

  trackDashboardProduct(id: string, name: string, isActiveProduct: boolean) {
    ReactGA.set({
      id,
      name,
    });

    ReactGA.event({
      category: 'Dashboard',
      action: isActiveProduct
        ? 'Clicked application tile'
        : 'Clicked start using application link',
    });
  }

  trackEditUserLanguage() {
    ReactGA.event({
      category: 'Dashboard',
      action: 'Submit language update',
    });
  }

  trackEditUserProfile() {
    ReactGA.event({
      category: 'Dashboard',
      action: 'Submits update to personal data',
    });
  }

  trackEditUserCookies() {
    ReactGA.event({
      category: 'Dashboard',
      action: 'Submits update to cookie preferences',
    });
  }

  trackEditUserPassword() {
    ReactGA.event({
      category: 'Dashboard',
      action: 'Submits password change',
    });
  }

  trackMFAActivate() {
    ReactGA.event({
      category: 'Dashboard',
      action: 'Turn on MFA',
    });
  }

  trackMFADeactivate() {
    ReactGA.event({
      category: 'Dashboard',
      action: 'Turn off MFA',
    });
  }

  trackMFAReset() {
    ReactGA.event({
      category: 'Dashboard',
      action: 'Reset MFA',
    });
  }
}

export default GAService;
