import { ReactComponent as IcoGoogleAuth } from 'assets/svg/u_ico_authentication_google.svg';
import { ReactComponent as IcoOkta } from 'assets/svg/u_ico_authentication_okta.svg';
import { ReactComponent as IcoPhone } from 'assets/svg/u_ico_authentication_phone.svg';
import { ReactComponent as IcoSms } from 'assets/svg/u_ico_authentication_sms.svg';

import { MfaMethod } from 'services/api/user.definitions';

const mapAuthMethod = (method: MfaMethod) => {
  switch (method) {
    case 'GOOGLE_AUTHENTICATOR': {
      return {
        title: 'dashboard.settings.auth.methods.google_auth.title',
        description: 'dashboard.settings.auth.methods.google_auth.description',
        icon: IcoGoogleAuth,
      };
    }
    case 'OKTA_VERIFY': {
      return {
        title: 'dashboard.settings.auth.methods.okta_verify.title',
        description: 'dashboard.settings.auth.methods.okta_verify.description',
        icon: IcoOkta,
      };
    }
    case 'SMS': {
      return {
        title: 'dashboard.settings.auth.methods.sms.title',
        description: 'dashboard.settings.auth.methods.sms.description',
        icon: IcoSms,
      };
    }
    case 'CALL': {
      return {
        title: 'dashboard.settings.auth.methods.phone.title',
        description: 'dashboard.settings.auth.methods.phone.description',
        icon: IcoPhone,
      };
    }
    default: {
      return null;
    }
  }
};

export default mapAuthMethod;
