import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ActivationStatus } from 'services/api/activation.definitions';
import { ErrorTrace } from 'services/error/error.types';

export interface ActivationState {
  status?: ActivationStatus;
  isLoading: boolean;
  isRenewing: boolean;
  isCompleting: boolean;
  redirectUrl?: string;
  tokenError?: ErrorTrace;
}

const initialState: ActivationState = {
  status: undefined,
  isLoading: false,
  isRenewing: false,
  isCompleting: false,
  redirectUrl: undefined,
  tokenError: undefined,
};

const activationSlice = createSlice({
  name: 'activation',
  initialState,
  reducers: {
    GET_ACTIVATION_START: (state) => {
      state.isLoading = true;
      state.tokenError = undefined;
    },
    GET_ACTIVATION_SUCCESS: (
      state,
      action: PayloadAction<{ status: ActivationState['status'] }>,
    ) => {
      state.isLoading = false;
      state.status = action.payload.status;
    },
    GET_ACTIVATION_FAIL: (state, action: PayloadAction<ErrorTrace>) => {
      state.isLoading = false;
      state.tokenError = action.payload;
    },
    RENEW_ACTIVATION_START: (state) => {
      state.isRenewing = true;
    },
    RENEW_ACTIVATION_COMPLETED: (state) => {
      state.isRenewing = false;
    },
    COMPLETE_ACTIVATION_START: (
      state,
      _action: PayloadAction<{ newPassword: string }>,
    ) => {
      state.isCompleting = true;
      state.redirectUrl = undefined;
    },
    COMPLETE_ACTIVATION_SUCCESS: (
      state,
      action: PayloadAction<{ redirectUrl: string }>,
    ) => {
      state.isCompleting = false;
      state.redirectUrl = action.payload.redirectUrl;
    },
    COMPLETE_ACTIVATION_FAIL: (state) => {
      state.isCompleting = false;
    },
  },
});

export default activationSlice;
