import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import intlSlice from 'store/intl/intl.slice';
import { getLocale, getEnableLocaleUpdate } from 'store/intl/intl.selectors';
import Navigation from './Navigation';

const NavigationContainer: React.FC = () => {
  const dispatch = useDispatch();
  const locale = useSelector(getLocale);
  const enableLocaleUpdate = useSelector(getEnableLocaleUpdate);

  const handleLanguageClick = (language: string) => {
    dispatch(intlSlice.actions.LOCALE_UPDATE(language));
  };

  return (
    <Navigation
      activeLanguage={locale}
      showMenuLanguage={enableLocaleUpdate}
      onLanguageChange={handleLanguageClick}
    />
  );
};
export default NavigationContainer;
