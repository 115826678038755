import React from 'react';
import { Typography, Box, IconButton, useTheme } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as IcoArrow } from 'assets/svg/u_ico_arrow_down.svg';

interface Props {
  onBack?: () => void;
}

const Title: React.FC<Props> = ({ onBack = () => {} }) => {
  const theme = useTheme();
  return (
    <Box display="flex" alignItems="center">
      <Box component="span" flex={1} flexShrink={0}>
        <IconButton
          component="button"
          onClick={onBack}
          style={{ transform: 'rotate(90deg)' }}
        >
          <IcoArrow
            title="Back"
            width={24}
            height={24}
            fill={theme.palette.primary.main}
          />
        </IconButton>
      </Box>
      <Box
        clone
        flex={5}
        textAlign="center"
        textOverflow="ellipsis"
        overflow="hidden"
      >
        <Typography variant="h2" color="primary">
          <FormattedMessage id="dashboard.settings.edit_itsme_settings.title" />
        </Typography>
      </Box>
      <Box component="span" flex={1} flexShrink={0} />
    </Box>
  );
};

export default Title;
