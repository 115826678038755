import * as Yup from 'yup';
import { FIELD_NAME } from './EditProfileForm.constants';

export const ValidationSchema = Yup.object().shape({
  [FIELD_NAME.FIRST_NAME]: Yup.string().required('form.error.required'),
  [FIELD_NAME.LAST_NAME]: Yup.string().required('form.error.required'),
  [FIELD_NAME.EMAIL]: Yup.string()
    .email('form.error.email')
    .required('form.error.required'),
});
