import { Button, withStyles } from '@material-ui/core';

const DestructiveButton = withStyles((theme) => ({
  root: {
    color: theme.palette.neutral01,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}))(Button);

export default DestructiveButton;
