import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorTrace } from 'services/error/error.types';

export interface SystemState {
  booting: boolean;
  ready: boolean;
  error?: ErrorTrace;
  showLoginLink: boolean;
  clientId?: string;
}

const initialState: SystemState = {
  booting: false,
  ready: false,
  error: undefined,
  showLoginLink: true,
  clientId: undefined,
};

const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    SYSTEM_START: (state) => {
      state.booting = true;
      state.ready = false;
    },
    SYSTEM_READY: (state) => {
      state.booting = false;
      state.ready = false;
    },
    SYSTEM_FAIL: (state, action: PayloadAction<ErrorTrace>) => {
      state.booting = false;
      state.ready = false;
      state.error = action.payload;
    },
    SYSTEM_SHOW_LOGIN_LINK: (state, action: PayloadAction<boolean>) => {
      state.showLoginLink = action.payload;
    },
    SYSTEM_SET_CLIENT_ID: (state, action: PayloadAction<string>) => {
      state.clientId = action.payload;
    },
  },
});

export default systemSlice;
