import React from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { gaService } from 'services';
import { ConsentValues } from 'pages/shared/Consent/components/ConsentForm/ConsentForm.types';
import userSlice from 'store/user/user.slice';
import { getUser, getIsUserUpdating } from 'store/user/user.selectors';
import EditConsentForm from './EditConsentForm';

interface Props {
  onSuccess?: () => void;
}

const EditConsentFormContainer: React.FC<Props> = ({
  onSuccess = () => console.info('success'),
}) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const isUserUpdating = useSelector(getIsUserUpdating);

  const handleSubmit = (values: ConsentValues) => {
    gaService.trackEditUserCookies();

    dispatch(
      userSlice.actions.UPDATE_USER_START({ cookies: values, onSuccess }),
    );
  };

  return (
    <Formik<ConsentValues>
      initialValues={user.cookies ?? { essential: true, analytics: false }}
      enableReinitialize // enable so we can reset to previous values on fail
      onSubmit={handleSubmit}
    >
      <EditConsentForm isSubmitting={isUserUpdating} />
    </Formik>
  );
};

export default EditConsentFormContainer;
