import React, { memo } from 'react';
import { Typography, Box, Link } from '@material-ui/core';
import { FormattedMessage, FormattedRelativeTime } from 'react-intl';

import getDateDiff from './get-date-diff';

interface Props {
  updatedDate?: string;
  onEdit?: () => void;
}

const Password: React.FC<Props> = ({
  updatedDate,
  onEdit = () => console.info('clicked edit'),
}) => (
  <section>
    <Box display="flex" justifyContent="space-between" mb={updatedDate ? 4 : 0}>
      <Typography component="h3" variant="h3" color="textPrimary">
        <FormattedMessage id="dashboard.settings.password.title" />
      </Typography>
      <Link component="button" onClick={onEdit}>
        <FormattedMessage id="general.edit" />
      </Link>
    </Box>

    {updatedDate && (
      <>
        <Box color="neutral03.300">
          <Typography color="inherit">
            <FormattedMessage id="dashboard.settings.password.last_edit.title" />
          </Typography>
        </Box>
        <Typography>
          <FormattedRelativeTime
            numeric="always"
            value={
              /* - 1 second to make sure it's never 0 */
              getDateDiff(new Date(updatedDate)) - 1
            }
            updateIntervalInSeconds={1}
          />
        </Typography>
      </>
    )}
  </section>
);

export default memo(Password);
