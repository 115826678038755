import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Title = styled(Typography)`
  font-size: 32px;
  color: #0a416b;
  margin-bottom: 16px;
  line-height: 40px;
`;

export const Wrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding-top: 64px;
  }
`;
