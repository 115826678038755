import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, CircularProgress, Typography, Button } from '@material-ui/core';

import Page from 'components/@layout/Page';
import Logo from 'components/Logo';

interface Props {
  isRenewing: boolean;
  onRenew?: () => void;
}

const ActivationExpired: React.FC<Props> = ({
  isRenewing = false,
  onRenew,
}) => (
  <Page>
    <Box textAlign="center" width="100%" mb={{ xs: 7, lg: 10 }}>
      <Logo />
    </Box>
    <Typography variant="h2" color="primary" gutterBottom>
      <FormattedMessage id="activation.expired.title" />
    </Typography>
    <Box mb={6}>
      <Typography color="textSecondary" gutterBottom>
        <FormattedMessage id="activation.expired.description" />
      </Typography>
    </Box>
    <Button
      type="button"
      variant="contained"
      color="primary"
      fullWidth
      disabled={isRenewing}
      onClick={onRenew}
    >
      {isRenewing ? (
        <CircularProgress color="secondary" size={28} />
      ) : (
        <FormattedMessage id="activation.expired.button.renew" />
      )}
    </Button>
  </Page>
);

export default ActivationExpired;
