import { Store, ReducersMapObject } from 'redux';
import { Saga } from 'redux-saga';
import {
  persistStore,
  persistReducer,
  PersistConfig,
  persistCombineReducers,
} from 'redux-persist';
import { Store as CombinedStore } from 'store/reducers';
import storage from 'redux-persist/lib/storage';
import { configureStore } from '@reduxjs/toolkit';
import _merge from 'lodash/merge';

import { middlewares, sagaMiddleware } from './middlewares';

interface Options {
  enableDevTools: boolean;
  persistConfig?: Partial<PersistConfig>;
  initApp?: (store: Store) => void;
}

const defaultOptions = {
  enableDevTools: true,
  persistConfig: {
    key: 'root',
    storage,
    version: 1,
    debug: true,
    whitelist: [],
  },
  initApp: () => {},
};

const generateStore = <ReducerType extends ReducersMapObject>(
  sagas: Saga,
  combinedReducers: ReducerType,
  options: Options,
) => {
  const opts = _merge(defaultOptions, options);
  const persistConfig = opts.persistConfig;

  const rootReducer = persistReducer(
    persistConfig,
    persistCombineReducers<CombinedStore>(persistConfig, combinedReducers),
  );

  const store = configureStore({
    reducer: rootReducer,
    middleware: middlewares,
    devTools: opts.enableDevTools,
  });

  sagaMiddleware.run(sagas);

  const persistor = persistStore(store);

  const initApp = () => opts.initApp(store);

  return { store, persistor, initApp };
};

export default generateStore;
