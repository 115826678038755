import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import { Container, Box, CircularProgress } from '@material-ui/core';

import applicationsSlice from 'store/applications/applications.slice';
import {
  getUser,
  getIsUserLoading,
  getUserError,
} from 'store/user/user.selectors';
import {
  getApplications,
  getIsApplicationsLoading,
  getApplicationsError,
} from 'store/applications/applications.selectors';
import userSlice from 'store/user/user.slice';
import DashboardOverview from 'pages/dashboard/pages/dashboard-overview';
import DashboardSettings from 'pages/dashboard/pages/dashboard-settings';
import DashboardProfile from 'pages/dashboard/pages/dashboard-profile';
import NotFound from 'pages/not-found';
import { Routes } from 'pages/routes.constants';
import ErrorPage from 'components/@layout/ErrorPage';

/**
 * everything inside dashboard is protected by a login
 * & needs data fetched in this component before the appropriate route is allowed to render
 */

const Dashboard = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const applications = useSelector(getApplications);
  const isApplicationsLoading = useSelector(getIsApplicationsLoading);
  const applicationsError = useSelector(getApplicationsError);

  const user = useSelector(getUser);
  const isUserLoading = useSelector(getIsUserLoading);
  const userError = useSelector(getUserError);

  const loading = isApplicationsLoading || isUserLoading;
  const error = applicationsError || userError;

  // fetch the critical dashboard data before rendering the route
  useEffect(() => {
    if (!error && !loading && (!applications.length || !user)) {
      dispatch(applicationsSlice.actions.GET_APPLICATIONS_START());
      dispatch(userSlice.actions.GET_USER_START());
    }
  });

  if (Boolean(error)) {
    const code = error?.code;
    const title = intl.messages[`page.error.${code}.title`];
    const description = intl.messages[`page.error.${code}.description`];

    return <ErrorPage code={code} title={title} description={description} />;
  }

  if (loading) {
    return (
      <Container fixed>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress size={40} />
        </Box>
      </Container>
    );
  }

  return (
    <>
      <Route component={NotFound} />
      <Route exact path={Routes.Dashboard} component={DashboardOverview} />
      <Route exact path={Routes.Settings} component={DashboardSettings} />
      <Route exact path={Routes.Profile} component={DashboardProfile} />
    </>
  );
};

export default Dashboard;
