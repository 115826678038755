import React from 'react';

import { gaService } from 'services';
import ProductTile, { ProductTileProps } from './ProductTile';

const ProductTileContainer: React.FC<ProductTileProps> = ({
  id,
  name,
  included = false,
  ...rest
}) => {
  const handleClick = () => {
    gaService.trackDashboardProduct(id, name, included);
  };

  return (
    <ProductTile
      id={id}
      name={name}
      included={included}
      onClick={handleClick}
      {...rest}
    />
  );
};

export default ProductTileContainer;
