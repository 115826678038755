import { createFilter } from 'redux-persist-transform-filter';

import { envService } from 'services';
import { generateStore } from 'packages/redux';
import { reducers } from './reducers';
import sagas from './sagas';
import systemSlice from './system/system.slice';

const saveUserSubset = createFilter('user', ['cookies']);

const store = generateStore(sagas, reducers, {
  initApp: (store) => {
    store.dispatch(systemSlice.actions.SYSTEM_START());
  },
  enableDevTools: envService.ENABLE_DEV_TOOLS,
  persistConfig: {
    key: envService.LOCALSTORAGE_ID,
    keyPrefix: '',
    whitelist: ['intl', 'user'],
    transforms: [saveUserSubset],
  },
});

export default store;
