import React, { useMemo } from 'react';
import Page from 'components/@layout/Page';
import { Wrap, Title } from '../styles';
import { Box, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { decodeState } from 'utils/okta.utils';
import { DecodedState, Flows } from 'types/state.types';

const ItsmeError = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const state = urlParams.get('state') ?? '';
  const errorDescription = urlParams.get('error_description') ?? undefined;
  const decodedState = decodeState<DecodedState>(state);

  const title = useMemo(() => {
    if (!decodedState) {
      return 'itsme.error.unknown.title';
    }

    switch (decodedState.flow) {
      case Flows.Activate:
        return 'itsme.error.activate.title';
      case Flows.LinkAccount:
        return 'itsme.error.link_account.title';

      case Flows.Register:
      case Flows.RegisterForm:
        return 'itsme.error.registration.title';

      default:
        return 'itsme.error.unknown.title';
    }
  }, [decodedState]);

  return (
    <Page scroll="paper">
      <Wrap>
        <Box>
          <Title variant="h1">
            <FormattedMessage id={title} />
          </Title>
          {errorDescription ? (
            <Typography>{errorDescription}</Typography>
          ) : (
            <Typography>
              <FormattedMessage id="itsme.error.unknown.description" />
            </Typography>
          )}
        </Box>
      </Wrap>
    </Page>
  );
};

export default ItsmeError;
