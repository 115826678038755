import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, useFormikContext } from 'formik';
import { Grid, Button, CircularProgress } from '@material-ui/core';

import { FormTextField } from 'components/@common/Form';
import { FIELD_NAME } from './EditProfileForm.constants';

interface Props {
  isSubmitting: boolean;
  isEmailDisabled: boolean;
}

const EditProfileForm: React.FC<Props> = ({
  isSubmitting,
  isEmailDisabled,
}) => {
  const intl = useIntl();
  const form = useFormikContext();

  return (
    <Form>
      <Grid container spacing={7}>
        <Grid item xs={12}>
          <FormTextField
            name={FIELD_NAME.FIRST_NAME}
            label={<FormattedMessage id="form.firstname.label" />}
            fullWidth
            autoFocus
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            name={FIELD_NAME.LAST_NAME}
            label={<FormattedMessage id="form.lastname.label" />}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            type="email"
            name={FIELD_NAME.EMAIL}
            label={<FormattedMessage id="form.email.label" />}
            fullWidth
            disabled={isEmailDisabled}
            helperText={
              isEmailDisabled && (
                <FormattedMessage
                  id="form.email.disabled.description"
                  tagName="span"
                  values={{
                    a: (t: string) => (
                      <a
                        href={intl.formatMessage({ id: 'general.contact.url' })}
                        target="__blank"
                      >
                        {t}
                      </a>
                    ),
                  }}
                />
              )
            }
            //
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={!form.isValid || isSubmitting}
          >
            {isSubmitting ? (
              <CircularProgress color="secondary" size={28} />
            ) : (
              <FormattedMessage id="dashboard.settings.edit_profile.submit" />
            )}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

export default EditProfileForm;
