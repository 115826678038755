import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { envService } from 'services';
import { isValidLocale } from 'services/i18n/config';

export const getBrowserLanguage = () => navigator.language.split('-')[0];

export interface IntlState {
  locale: string;
  enableUpdate: boolean;
}

const browserLocale = getBrowserLanguage();

const initialState: IntlState = {
  locale: isValidLocale(browserLocale)
    ? browserLocale
    : envService.DEFAULT_LOCALE,
  enableUpdate: true,
};

const intlSlice = createSlice({
  name: 'intl',
  initialState,
  reducers: {
    LOCALE_UPDATE: (state, action: PayloadAction<string>) => {
      state.locale = action.payload.toLowerCase();
    },
    LOCALE_ENABLE_UPDATE: (state, action: PayloadAction<boolean>) => {
      state.enableUpdate = action.payload;
    },
  },
});

export default intlSlice;
