import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import userSlice from 'store/user/user.slice';
import { getIsUserUpdating, getUser } from 'store/user/user.selectors';
import ItsmeSettingsForm from './ItsmeSettingsForm';
import { FIELD_NAME } from './constants';
import { Formik, FormikProps } from 'formik';
import { ValidationSchema } from './form-validation';
import { ItsmeUnlinkFormValues } from './types';
import { DestructiveButton } from 'components/@common/Button';
import { FormattedMessage } from 'react-intl';
import ItsmeSettingsUnlinkConfirmDialog from './ItsmeSettingsUnlinkConfirmDialog';
import { CircularProgress, withStyles } from '@material-ui/core';

interface Props {
  onSuccess?(): void;
}

const ItsmeSettingsFormContainer: React.FC<Props> = ({
  onSuccess = () => console.info('success'),
}) => {
  const formRef = useRef<FormikProps<ItsmeUnlinkFormValues> | null>(null);
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const isUserUpdating = useSelector(getIsUserUpdating);

  const [showWarningDialog, setShowWarningDialog] = useState(false);

  const handleUnlinkIntermediatePress = () => {
    setShowWarningDialog(true);
  };

  const handleUnlinkConfirmPress = () => {
    formRef.current?.submitForm();
    setShowWarningDialog(false);
  };

  const handleWarningDialogClose = () => {
    setShowWarningDialog(false);
  };

  const handleSubmit = (values: ItsmeUnlinkFormValues) => {
    dispatch(
      userSlice.actions.UNLINK_ITSME_START({
        email: values.email,
        password: values.password,
        onSuccess,
      }),
    );
  };

  return (
    <>
      <Formik<ItsmeUnlinkFormValues>
        initialValues={{
          [FIELD_NAME.EMAIL]: user.email ?? '',
          [FIELD_NAME.PASSWORD]: '',
          [FIELD_NAME.PASSWORD_NEW_CONFIRM]: '',
        }}
        validationSchema={ValidationSchema}
        enableReinitialize
        validateOnChange
        validateOnMount
        onSubmit={handleSubmit}
        innerRef={formRef}
      >
        {({ isValid }) => (
          <ItsmeSettingsForm>
            <DestructiveButton
              type="button"
              variant="contained"
              fullWidth
              onClick={handleUnlinkIntermediatePress}
              disabled={!isValid}
            >
              {isUserUpdating ? (
                <CustomCircularProgress size={28} />
              ) : (
                <FormattedMessage id="dashboard.settings.edit_itsme_settings.section.unlink.button" />
              )}
            </DestructiveButton>
            <ItsmeSettingsUnlinkConfirmDialog
              isOpen={showWarningDialog}
              onClose={handleWarningDialogClose}
              onConfirmClick={handleUnlinkConfirmPress}
            />
          </ItsmeSettingsForm>
        )}
      </Formik>
    </>
  );
};

export default ItsmeSettingsFormContainer;

const CustomCircularProgress = withStyles((theme) => ({
  root: {
    color: theme.palette.neutral01,
  },
}))(CircularProgress);
