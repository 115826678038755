import { Theme } from '@material-ui/core';

const createOverrides = (theme: Theme): Theme['overrides'] => ({
  MuiCssBaseline: {
    '@global': {
      'html, body': {
        fontSize: theme.typography.fontSize,
        fontFamily: theme.typography.fontFamily,
      },
      input: {
        '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active': {
          fontSize: 'inherit',
          '-webkit-transition-delay': '9999s',
        },
      },
      button: {
        fontSize: 'inherit',
        fontFamily: theme.typography.fontFamily,
      },
    },
  },
  MuiButton: {
    root: {
      border: 'none',
      borderRadius: theme.shape.borderRadius,
      // backgroundColor: theme.palette.brand01[500],
      // color: theme.palette.neutral01,
      fontWeight: theme.typography.fontWeightBold,
      padding: theme.spacing(3, 5),
      textTransform: 'none',
    },
    containedPrimary: {
      color: theme.palette.neutral01,
      backgroundColor: theme.palette.brand01[400],
      '&:hover': {
        color: theme.palette.neutral01,
        backgroundColor: theme.palette.brand01[500],
      },
      '&$disabled': {
        color: theme.palette.neutral01,
        backgroundColor: theme.palette.brand01[100],
      },
    },
    containedSecondary: {
      color: theme.palette.brand01[400],
      backgroundColor: theme.palette.brand02[300],
      '&:hover': {
        color: theme.palette.brand01[400],
        backgroundColor: theme.palette.brand02[400],
      },
      '&$disabled': {
        color: theme.palette.brand01[100],
        backgroundColor: theme.palette.brand02[100],
      },
    },
  },
  MuiDivider: {
    root: {
      backgroundColor: theme.palette.brand02[300],
    },
  },
  MuiTypography: {
    gutterBottom: {
      marginBottom: theme.spacing(4),
    },
  },
  MuiLink: {
    root: {
      '&:hover, &:focus': {
        color: theme.palette.brand01[500],
      },
    },
  },
  MuiSwitch: {
    root: {
      width: 42,
      height: 24,
      padding: 0,
      display: 'flex',
    },
    colorPrimary: {
      '&$checked': {
        color: theme.palette.neutral01,
      },
      '&$disabled': {
        color: theme.palette.neutral03[300],
        '& + $track': {
          borderColor: theme.palette.neutral03[200],
          backgroundColor: theme.palette.neutral01,
        },
        '&$checked': {
          '& + $track': {
            backgroundColor: theme.palette.neutral03[200],
          },
        },
      },
    },
    switchBase: {
      padding: 4,
      color: theme.palette.primary.main,
      '&$checked': {
        transform: 'translateX(18px)',
        color: theme.palette.neutral01,
        '& + $track': {
          opacity: 1,
        },
      },
      '&$disabled': {
        '& + $track': {
          opacity: 1,
        },
      },
    },
    thumb: {
      width: 16,
      height: 16,
      boxShadow: 'none',
    },
    track: {
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: 16,
      opacity: 1,
      backgroundColor: theme.palette.neutral01,
    },
  },
  MuiList: {
    padding: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  MuiListItem: {
    root: {
      '&:hover': {
        '&$selected': {
          backgroundColor: theme.palette.neutral03[100],
        },
      },
      '&$selected': {
        fontWeight: 'bold',
        backgroundColor: 'transparent',
      },
    },
    button: {
      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.neutral03[100],
      },
    },
  },
  MuiMenu: {
    paper: {
      minWidth: 152,
      marginTop: theme.spacing(3),
    },
  },
  MuiMenuItem: {
    root: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  MuiFormLabel: {
    root: {
      '&&$focused': {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
      },
      '&$disabled': {
        color: theme.palette.text.secondary,
      },
      '&$error': {
        color: theme.palette.text.secondary,
      },
    },
  },
  MuiFormHelperText: {
    root: {
      fontSize: 'inherit',
      marginTop: theme.spacing(1),
    },
  },
  MuiInputLabel: {
    shrink: {
      position: 'relative',
      transform: 'none',
    },
  },
  MuiInput: {
    input: {
      '&$focused': {
        borderColor: 'red',
      },
      '&$disabled': {
        cursor: 'not-allowed',
      },
    },
    formControl: {
      'label + &': {
        marginTop: theme.spacing(2),
      },
    },
  },
  MuiInputBase: {
    root: {
      backgroundColor: theme.palette.neutral01,
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.neutral03[200]}`,
      boxShadow: 'none',
      '&:hover:not($disabled)': {
        borderColor: theme.palette.neutral03[400],
      },
      '&&$focused': {
        // boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}`,
        borderColor: theme.palette.primary.main,
      },
      '&$disabled': {
        opacity: 0.3,
      },
    },
    input: {
      lineHeight: 1,
      boxSizing: 'border-box',
      height: 48,
      padding: theme.spacing(3, 4),
    },
  },
  MuiInputAdornment: {
    root: {
      height: '100%',
      maxHeight: '100%',
      padding: theme.spacing(3, 4),
      color: theme.palette.neutral03[400],
      backgroundColor: theme.palette.neutral03[100],
    },
    positionStart: {
      marginRight: 0,
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
    },
    positionEnd: {
      marginLeft: 0,
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },
  },
  MuiFormControlLabel: {
    root: {
      borderRadius: theme.shape.borderRadius,
      marginRight: 0,
      marginLeft: 0,
      padding: theme.spacing(2, 1),
      '&:hover:not($disabled)': {
        backgroundColor: theme.palette.neutral03[100],
      },
      '&$disabled': {
        cursor: 'not-allowed',
      },
    },
    label: {
      color: theme.palette.neutral03[400],
      marginLeft: theme.spacing(3),
      '&$disabled': {
        color: theme.palette.neutral03[400],
        cursor: 'not-allowed',
      },
    },
  },
  MuiCheckbox: {
    root: {
      padding: 0,
      '&$disabled': {
        opacity: 0.1,
      },
    },
  },
  MuiRadio: {
    root: {
      padding: 0,
      '&$disabled': {
        opacity: 0.1,
      },
    },
  },
  MuiIconButton: {
    root: {
      padding: theme.spacing(2),
    },
  },
  MuiSnackbarContent: {
    root: {
      alignItems: 'flex-start',
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.secondary.main,
      width: '100%',
      padding: theme.spacing(4),
      '&& #notistack-icon': {
        flexShrink: 0,
        marginRight: theme.spacing(4),
      },
    },
    message: {
      flex: 1,
      padding: 0,
    },
  },
  MuiListItemIcon: {
    root: {
      minWidth: 'auto',
      marginRight: theme.spacing(5),
    },
  },
});

export { createOverrides };
