import React from 'react';

import Page from 'components/@layout/Page';
import Title from './components/Title';
import EditProfileForm from './components/EditProfileForm';

interface Props {
  onBack?: () => void;
  onSuccess?: () => void;
}

const EditProfile: React.FC<Props> = ({
  onBack = () => console.info('go back'),
  onSuccess = () => console.info('success'),
}) => (
  <Page scroll="paper" DialogTitleComponent={<Title onBack={onBack} />}>
    <EditProfileForm onSuccess={onSuccess} />
  </Page>
);

export default EditProfile;
