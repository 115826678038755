import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import { ReactComponent as IcoChevron } from 'assets/svg/u_ico_arrow_down.svg';
import options from './language-options';

interface Props {
  activeLanguage: string;
  onChange?: (language: string) => void;
}

const MenuLanguage: React.FC<Props> = ({
  activeLanguage,
  onChange = () => {},
}) => {
  const theme = useTheme();

  const initialSelectedIndex = options.findIndex(
    (o) => o.code === activeLanguage,
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(
    initialSelectedIndex,
  );

  const open = Boolean(anchorEl);

  const handleClick = (
    _e: React.MouseEvent<HTMLElement>,
    index: number,
    option: typeof options[number],
  ) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    onChange(option.code);
  };

  const handleOpen = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        color="primary"
        endIcon={
          <IcoChevron
            title=""
            fill={theme.palette.primary.main}
            width={24}
            height={24}
          />
        }
        onClick={handleOpen}
      >
        {options[selectedIndex]?.code.toUpperCase()}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleClose}
      >
        {options.map((l, index) => (
          <MenuItem
            key={l.code}
            selected={selectedIndex === index}
            onClick={(e) => handleClick(e, index, l)}
          >
            <FormattedMessage id={l.label} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MenuLanguage;
