import React from 'react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';

import { gaService } from 'services';
import userSlice from 'store/user/user.slice';
import ConsentForm from './ConsentForm';
import { ConsentValues } from './ConsentForm.types';

const ConsentFormContainer: React.FC = () => {
  const dispatch = useDispatch();

  const handleSubmit = (values: ConsentValues) => {
    values.analytics === true ? gaService.enable() : gaService.disable();
    dispatch(userSlice.actions.SET_COOKIES_LOCAL(values));
  };

  return (
    <>
      <Formik<ConsentValues>
        initialValues={{ essential: true, analytics: false }}
        onSubmit={handleSubmit}
      >
        <ConsentForm />
      </Formik>
    </>
  );
};

export default ConsentFormContainer;
