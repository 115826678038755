import React from 'react';
import Box from '@material-ui/core/Box';

import MenuLanguage from 'components/MenuLanguage';

interface Props {
  activeLanguage: string;
  showMenuLanguage?: boolean;
  onLanguageChange: (language: string) => void;
}

const Navigation: React.FC<Props> = ({
  activeLanguage,
  showMenuLanguage = true,
  onLanguageChange,
}) => (
  <Box
    component="nav"
    display="flex"
    position="relative"
    zIndex={9999999}
    m={4}
  >
    <Box flex="1" />
    {showMenuLanguage && (
      <MenuLanguage
        activeLanguage={activeLanguage}
        onChange={onLanguageChange}
      />
    )}
  </Box>
);

export default Navigation;
