import React from 'react';
import { Box, useTheme, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as IcoArrow } from 'assets/svg/u_ico_arrow_right.svg';
import { ReactComponent as IcoExternal } from 'assets/svg/u_ico_external_link.svg';
import { Application } from 'services/api/dashboard.definitions';
import { LinkStyled, LogoWrap, Logo } from './ProductTile.style';

export type ProductTileProps = Pick<Application, 'id' | 'name' | 'logoUrl'> & {
  url: string;
  included?: boolean;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

const ProductTile: React.FC<ProductTileProps> = ({
  id,
  name,
  url,
  logoUrl,
  included = false,
  onClick,
}) => {
  const theme = useTheme();

  return (
    <LinkStyled key={id} href={url} underline="none" onClick={onClick}>
      <LogoWrap>
        <Logo src={logoUrl} alt="logo" />
      </LogoWrap>

      <Box flex={1}>
        {included ? (
          name
        ) : (
          <Typography component="span" color="textSecondary">
            <FormattedMessage
              id="application_tile.not_included"
              values={{
                name: <Typography color="primary">{name}</Typography>,
              }}
            />
          </Typography>
        )}
      </Box>

      <Box clone flexShrink={0} ml={{ xs: 4, lg: 6 }}>
        {included ? (
          <IcoArrow
            title=""
            width={24}
            height={24}
            fill={theme.palette.brand01[300]}
          />
        ) : (
          <IcoExternal
            title=""
            width={24}
            height={24}
            fill={theme.palette.brand01[300]}
          />
        )}
      </Box>
    </LinkStyled>
  );
};

export default ProductTile;
