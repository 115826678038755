import EnvService from 'services/env';

// we don't put this in the initServices function in redux
// because we already need some base values before that.
// the api values are later added to the service once fetched

const envService = new EnvService({
  NAME: process.env.REACT_APP_NAME,
  VERSION: process.env.REACT_APP_VERSION,
  PORT: Number(process.env.REACT_APP_PORT),
  ENABLE_DEV_TOOLS: Boolean(process.env.REACT_APP_ENABLE_DEV_TOOLS),
  DEFAULT_CURRENCY: process.env.REACT_APP_DEFAULT_CURRENCY,
  DEFAULT_LOCALE: process.env.REACT_APP_DEFAULT_LOCALE,
  DEFAULT_LOCALE_DATE: process.env.REACT_APP_DEFAULT_LOCALE_DATE,
});

export default envService;
