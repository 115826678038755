import { SagaReturnType, put, select } from 'redux-saga/effects';

import QUERY_PARAMS from 'constants/query-params';
import { isValidLocale } from 'services/i18n/config';
import { getQueryParams } from 'store/route/route.selectors';
import { getUser } from 'store/user/user.selectors';
import intlSlice from './intl.slice';

export function* setLocaleByQueryParam() {
  const params: SagaReturnType<typeof getQueryParams> = yield select(
    getQueryParams,
  );
  const forceLanguageParam = params[QUERY_PARAMS.FORCE_LANGUAGE];

  if (
    forceLanguageParam &&
    typeof forceLanguageParam === 'string' &&
    isValidLocale(forceLanguageParam)
  ) {
    yield put(intlSlice.actions.LOCALE_UPDATE(forceLanguageParam));
    yield put(intlSlice.actions.LOCALE_ENABLE_UPDATE(false));
  } else {
    yield put(intlSlice.actions.LOCALE_ENABLE_UPDATE(true));
  }
}

export function* setUserLocale() {
  const user: ReturnType<typeof getUser> = yield select(getUser);
  if (user.language) {
    yield put(intlSlice.actions.LOCALE_UPDATE(user.language));
  }
}
