import { Button, Typography, styled } from '@material-ui/core';

export const Wrap = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flex: 1,
    paddingTop: theme.spacing(8),
    height: '100%',
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: '#0A416B',
  marginBottom: theme.spacing(5),
  fontSize: 32,
}));

export const PrimaryButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
