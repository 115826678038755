import { Link, withStyles, createStyles } from '@material-ui/core';
import styled from 'styled-components';

export const LinkStyled = withStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.neutral01,
      padding: theme.spacing(4),
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.brand02[300]}`,
      '&:hover, &:active': {
        backgroundColor: theme.palette.brand02[300],
      },
    },
  }),
)(Link);

export const LogoWrap = styled.div`
  display: inherit;
  flex-shrink: 0;
  margin-right: ${({ theme }) => theme.spacing(4)}px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  overflow: hidden;
  box-shadow: 0px 2px 4px rgba(51, 51, 51, 0.12);
  background-color: ${({ theme }) => theme.palette.neutral01};

  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin-right: ${({ theme }) => theme.spacing(6)}px;
  }
`;

export const Logo = styled.img`
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
  }
`;
