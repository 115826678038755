import { createGlobalStyle, DefaultTheme } from 'styled-components';

import './normalize.css';
import './fonts.css';

export default createGlobalStyle<{ theme: DefaultTheme }>`
  * {
    box-sizing: border-box;
  }

  ul,
  ol {
    padding-left: ${({ theme }) => theme.spacing(6)}px;
  }
`;
