import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Box, Typography, Divider, Link } from '@material-ui/core';
import qs from 'qs';
import { useSelector, useDispatch } from 'react-redux';

import { envService } from 'services';
import QUERY_PARAMS from 'constants/query-params';
import registerSlice from 'store/register/register.slice';
import { getRegisteredEmail } from 'store/register/register.selectors';
import { getShowLoginLink } from 'store/system/system.selectors';
import { getLocaleQs } from 'store/intl/intl.selectors';
import Page from 'components/@layout/Page';
import Logo from 'components/Logo';
import RegisterConfirm from './RegisterConfirm';
import RegisterForm from './components/RegisterForm';
import RegisterOptions from './RegisterOptions';
import history from 'packages/history';

const Register: React.FC<RouteComponentProps> = ({ location }) => {
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const dispatch = useDispatch();
  const registeredEmail = useSelector(getRegisteredEmail);
  const showLoginLink = useSelector(getShowLoginLink);
  const loginLinkQuery = useSelector(getLocaleQs);

  const handleReset = () => {
    dispatch(registerSlice.actions.FORM_RESET());
  };

  const handleSelect = () => {
    const search = new URLSearchParams(location.search);
    search.delete(QUERY_PARAMS.SHOW_OPTIONS);

    history.push({
      search: search.toString(),
    });
  };

  if (query[QUERY_PARAMS.SHOW_OPTIONS] === 'true') {
    return <RegisterOptions onSelect={handleSelect} />;
  }

  if (registeredEmail) {
    return (
      <RegisterConfirm
        email={registeredEmail}
        webviewCloseUri={query[QUERY_PARAMS.WEBVIEW_CLOSE_URI]}
        onReset={handleReset}
      />
    );
  }

  return (
    <Page>
      <Box textAlign="center" width="100%" mb={{ xs: 7, lg: 10 }}>
        <Logo />
      </Box>

      <Typography variant="h2" color="primary" gutterBottom>
        <FormattedMessage id="register.title" />
      </Typography>

      <RegisterForm
        initialFirstname={query[QUERY_PARAMS.DEFAULT_FIRSTNAME]}
        initialLastname={query[QUERY_PARAMS.DEFAULT_LASTNAME]}
        initialEmail={query[QUERY_PARAMS.DEFAULT_EMAIL]}
      />

      {showLoginLink && (
        <>
          <Box mt={6} mb={6}>
            <Divider />
          </Box>
          <Typography color="textSecondary">
            <Box component="span" mr={3}>
              <FormattedMessage id="register.meta.already_have_an_account" />
            </Box>
            <Link href={`${envService.IDP_BASE}?${loginLinkQuery}`}>
              <FormattedMessage id="general.login.label" />
            </Link>
          </Typography>
        </>
      )}
    </Page>
  );
};

export default Register;
