// juice for large class setup: https://stackoverflow.com/a/52393598
// import ErrorService from 'services/error';

import {
  createUser,
  getUser,
  updateUser,
  changePassword,
  resetPassword,
  activateMFA,
  deactivateMFA,
  resetMFA,
  updateUserCredentials,
  partialUpdateUser,
} from './user.api';
import {
  getActivation,
  renewActivation,
  completeActivation,
} from './activation.api';
import { getApplications } from './dashboard.api';

interface Options<ApiInstance> {
  api: ApiInstance;
}

class RestApiService<ApiInstance> {
  public api: ApiInstance;

  constructor(options: Options<ApiInstance>) {
    this.api = options.api;
  }

  // USER
  public createUser = createUser;
  public getUser = getUser;
  public updateUser = updateUser;
  public updateUserCredentials = updateUserCredentials;
  public partialUpdateUser = partialUpdateUser;
  public changePassword = changePassword;
  public resetPassword = resetPassword;
  public activateMFA = activateMFA;
  public deactivateMFA = deactivateMFA;
  public resetMFA = resetMFA;

  // ACTIVATION
  public getActivation = getActivation;
  public renewActivation = renewActivation;
  public completeActivation = completeActivation;

  // DASHBOARD
  public getApplications = getApplications;
}

export default RestApiService;
