import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, useFormikContext } from 'formik';
import { Grid, Button, CircularProgress } from '@material-ui/core';

import { FormPassword } from 'components/@common/Form';
import { FIELD_NAME } from './EditPasswordForm.constants';

interface Props {
  isSubmitting: boolean;
}

const EditPasswordForm: React.FC<Props> = ({ isSubmitting }) => {
  const form = useFormikContext();

  return (
    <Form>
      <Grid container spacing={7}>
        <Grid item xs={12}>
          <FormPassword
            name={FIELD_NAME.PASSWORD_OLD}
            label={<FormattedMessage id="form.password_old.label" />}
            autoComplete="current-password"
            fullWidth
            autoFocus
          />
        </Grid>
        <Grid item xs={12}>
          <FormPassword
            name={FIELD_NAME.PASSWORD_NEW}
            autoComplete="new-password"
            label={<FormattedMessage id="form.password_new.label" />}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormPassword
            name={FIELD_NAME.PASSWORD_NEW_CONFIRM}
            autoComplete="new-password"
            label={<FormattedMessage id="form.password_repeat.label" />}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={!form.isValid || isSubmitting}
          >
            {isSubmitting ? (
              <CircularProgress color="secondary" size={28} />
            ) : (
              <FormattedMessage id="dashboard.settings.edit_password.submit" />
            )}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

export default EditPasswordForm;
