import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TextField, TextFieldProps, Box } from '@material-ui/core';
import { useField } from 'formik';

import HelperText from 'components/@common/HelperText';

const FormTextField: React.FC<TextFieldProps> = ({
  name = '---DEFAULT_NAME---',
  type,
  helperText,
  ...rest
}) => {
  const [field, meta] = useField({ name, type });
  return (
    <TextField
      {...field}
      type={type}
      error={Boolean(meta.error)}
      helperText={
        <>
          {helperText && (
            <HelperText variant="default">{helperText}</HelperText>
          )}
          {Boolean(meta.error) && meta.touched && (
            <Box clone mt={helperText ? 1 : 0}>
              <HelperText variant="error">
                <FormattedMessage id={meta.error} />
              </HelperText>
            </Box>
          )}
        </>
      }
      {...rest}
    />
  );
};

export default FormTextField;
