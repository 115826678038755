import { all } from 'redux-saga/effects';

import watchSystem from './system/system.saga';
import watchRegister from './register/register.saga';
import watchUser from './user/user.saga';
import watchActivation from './activation/activation.saga';
import watchApplications from './applications/applications.saga';
import watchFormEditPassword from './form-edit-password/formEditPassword.saga';

export default function* sagas() {
  yield all([
    watchSystem(),
    watchRegister(),
    watchUser(),
    watchActivation(),
    watchApplications(),
    watchFormEditPassword(),
  ]);
}
