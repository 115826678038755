import { Box, Link, Typography } from '@material-ui/core';
import Page from 'components/@layout/Page';
import { ItsmeButton } from 'components/@okta';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { envService } from 'services';
import { getLocaleQs } from 'store/intl/intl.selectors';
import { PrimaryButton, Title, Wrap } from './styles';
import qs from 'query-string';
interface Props {
  onSelect(): void;
}

const RegisterOptions = ({ onSelect }: Props) => {
  const params = new URLSearchParams(window.location.search);
  const loginLinkQuery = useSelector(getLocaleQs);

  const flow = params.get('flow') ?? '';
  const origin = params.get('origin') ?? '';
  const client_id = params.get('client_id') ?? '';

  const itsmeLink = qs.stringifyUrl({
    url: envService.ITSME_CLIENT_URL,
    query: {
      client_id: envService.ITSME_CLIENT_ID,
      scope: envService.ITSME_SCOPE,
      response_type: 'code',
      redirect_uri: envService.ITSME_CALLBACK_URL,
      state: btoa(JSON.stringify({ flow, origin, client_id })),
    },
  });

  return (
    <Page scroll="paper">
      <Wrap>
        <Title variant="h1">
          <FormattedMessage id="register.options.title" />
        </Title>
        <Typography color="textSecondary">
          <FormattedMessage
            id="register.options.description"
            values={{
              b: (chunks: string[]) => <strong>{chunks}</strong>,
            }}
          />
        </Typography>
        <Box mt={7} mb={5}>
          <ItsmeButton href={itsmeLink} type="register" />
          <PrimaryButton
            variant="contained"
            color="primary"
            fullWidth
            onClick={onSelect}
          >
            <FormattedMessage id="register.options.button.email" />
          </PrimaryButton>
        </Box>
        <Box textAlign="center">
          <Typography color="textSecondary">
            <Box component="span" mr={1}>
              <FormattedMessage id="register.meta.already_have_an_account" />
            </Box>
            <Link href={`${envService.IDP_BASE}?${loginLinkQuery}`}>
              <FormattedMessage id="general.login.label" />
            </Link>
          </Typography>
        </Box>
      </Wrap>
    </Page>
  );
};

export default RegisterOptions;
