import React from 'react';
import { Switch } from 'react-router';
import { Router } from 'react-router-dom';
import { Route, Redirect } from 'react-router-dom';
import { Security, SecureRoute } from '@okta/okta-react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';

import { authService } from 'services';
import history from 'packages/history';
import { getUserCookies } from 'store/user/user.selectors';
import Consent from 'pages/shared/Consent';
import Dashboard from 'pages/dashboard';
import Register from 'pages/register';
import Activation from 'pages/activation';
import NotFound from 'pages/not-found';
import RouteBoundary from 'components/@boundaries/RouteBoundary';
import Navigation from 'components/@common/Navigation';
import { Routes } from './routes.constants';
import OktaAuth, { toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback } from 'components/@okta';
import {
  Activate,
  SignIn,
  Callback,
  SuccessFullyLinked,
  ItsmeError,
} from './itsme';
import RegisterOptions from './register/RegisterOptions';

const Pages = () => {
  const consent = useSelector(getUserCookies);

  const handleRestoreOriginalUri = async (_: OktaAuth, originalUri: string) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  return (
    <RouteBoundary>
      <Router history={history}>
        <Security
          oktaAuth={authService}
          restoreOriginalUri={handleRestoreOriginalUri}
        >
          <Switch>
            <Redirect exact from={Routes.Root} to={Routes.Dashboard} />
            {/* render all dashboard related routes behind a secured route */}
            <SecureRoute path={Routes.Dashboard} component={Dashboard} />
            <Route
              exact
              path={Routes.LoginCallback}
              component={LoginCallback}
            />
            <Route>
              {/* always add navigation to these routes as they're not protected - it hides itself if force_language param is present */}
              <Box position="absolute" top={0} left={0} right={0}>
                <Navigation />
              </Box>
              {Boolean(consent) ? (
                <Switch>
                  <Route exact path={Routes.Register} component={Register} />
                  <Route
                    exact
                    path={Routes.RegisterOptions}
                    component={RegisterOptions}
                  />
                  <Route
                    exact
                    path={Routes.UserActivation}
                    component={Activation}
                  />
                  <Route path={Routes.ItsmeActivate} component={Activate} />
                  <Route path={Routes.ItsmeSignIn} component={SignIn} />
                  <Route path={Routes.ItsmeCallback} component={Callback} />
                  <Route path={Routes.ItsmeError} component={ItsmeError} />
                  <Route
                    path={Routes.ItsmeSuccessfullyLinked}
                    component={SuccessFullyLinked}
                  />
                  <Route path="/" component={NotFound} />
                </Switch>
              ) : (
                <Consent open={!Boolean(consent)} />
              )}
            </Route>
          </Switch>
        </Security>
      </Router>
    </RouteBoundary>
  );
};

export default Pages;
