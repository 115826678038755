import { AxiosInstance } from 'axios';

import RestApiService from './';
import {
  CurrentUser,
  UpdateUserCredentialsPayload,
  PartialUpdateCurrentUserPayload,
  UserCookies,
} from './user.definitions';

export async function createUser(
  this: RestApiService<AxiosInstance>,
  data: {
    firstName: string;
    lastName: string;
    email: string;
    acceptTerms: boolean;
    acceptPrivacy: boolean;
    language: string;
    cookies: UserCookies;
    itsmeCode?: string;
  },
  activationToken?: string,
) {
  const response = await this.api.post<{ activationToken?: string }>(
    '/public_api/users',
    data,
    activationToken
      ? {
          headers: {
            'X-Onboarding-Token': activationToken,
          },
        }
      : undefined,
  );
  return response;
}

export async function getUser(this: RestApiService<AxiosInstance>) {
  const response = await this.api.get<CurrentUser>('/api/user');
  return response;
}

export async function partialUpdateUser(
  this: RestApiService<AxiosInstance>,
  patchPayload: PartialUpdateCurrentUserPayload,
) {
  return this.api.patch<null>('/api/user', patchPayload);
}

export async function updateUser(
  this: RestApiService<AxiosInstance>,
  user: Pick<
    CurrentUser,
    'firstName' | 'lastName' | 'email' | 'language' | 'cookies'
  >,
) {
  const response = await this.api.put<CurrentUser>('/api/user', user);
  return response;
}

export async function updateUserCredentials(
  this: RestApiService<AxiosInstance>,
  body: UpdateUserCredentialsPayload,
) {
  const response = await this.api.put('/api/user/credentials', body);

  return response;
}

export async function changePassword(
  this: RestApiService<AxiosInstance>,
  passwords: { passwordOld: string; passwordNew: string },
) {
  const response = await this.api.post<void>('/api/user/passwords', passwords);
  return response;
}

export async function resetPassword(
  this: RestApiService<AxiosInstance>,
  userId: string,
) {
  const response = await this.api.post<void>('/api/user/password/reset', {
    userId,
  });
  return response;
}

export async function activateMFA(this: RestApiService<AxiosInstance>) {
  const response = await this.api.post<{}>('/api/user/mfa');
  return response;
}

export async function deactivateMFA(this: RestApiService<AxiosInstance>) {
  const response = await this.api.delete('/api/user/mfa');
  return response;
}

export async function resetMFA(this: RestApiService<AxiosInstance>) {
  const response = await this.api.put<{}>('/api/user/mfa/reset');
  return response;
}
