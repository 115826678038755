import React from 'react';

import Page from 'components/@layout/Page';
import Title from './components/Title';
import EditLanguageForm from './components/EditLanguageForm';

interface Props {
  onBack?: () => void;
  onSuccess?: () => void;
}

const EditLanguage: React.FC<Props> = ({
  onBack = () => console.info('go back'),
  onSuccess = () => console.info('success'),
}) => (
  <Page scroll="paper" DialogTitleComponent={<Title onBack={onBack} />}>
    <EditLanguageForm onSuccess={onSuccess} />
  </Page>
);

export default EditLanguage;
