import React from 'react';
import { Box, Typography, Button, CircularProgress } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Form, useFormikContext } from 'formik';

import { FormSwitch } from 'components/@common/Form';
import { FIELD_NAME } from './ConsentForm.constants';

interface Props {
  isSubmitting?: boolean;
}

const ConsentForm: React.FC<Props> = ({ isSubmitting = false }) => {
  const form = useFormikContext();

  return (
    <Form>
      <Box mb={8}>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography variant="h3" color="textPrimary">
            <Box component="span" fontWeight="bold">
              <FormattedMessage id="consent.option.essential.title" />
            </Box>
          </Typography>
          <Box ml={4}>
            <FormSwitch name={FIELD_NAME.ESSENTIAL} disabled />
          </Box>
        </Box>
        <Typography color="textSecondary">
          <FormattedMessage id="consent.option.essential.description" />
        </Typography>
      </Box>

      <Box mb={8}>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography variant="h3" color="textPrimary">
            <Box component="span" fontWeight="bold">
              <FormattedMessage id="consent.option.analytics.title" />
            </Box>
          </Typography>
          <Box ml={4}>
            <FormSwitch name={FIELD_NAME.ANALYTICS} autoFocus />
          </Box>
        </Box>
        <Typography color="textSecondary">
          <FormattedMessage id="consent.option.analytics.description" />
        </Typography>
      </Box>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        disabled={!form.isValid || isSubmitting}
      >
        {isSubmitting ? (
          <CircularProgress color="secondary" size={28} />
        ) : (
          <FormattedMessage id="consent.submit.label" />
        )}
      </Button>
    </Form>
  );
};

export default ConsentForm;
