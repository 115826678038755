import {
  all,
  call,
  put,
  takeLatest,
  select,
  SagaReturnType,
} from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import { initServices, envService, gaService } from 'services';
import { EnvApiConfiguration } from 'services/env';
import QUERY_PARAMS from 'constants/query-params';
import { setLocaleByQueryParam } from 'store/intl/intl.saga';
import userSlice from 'store/user/user.slice';
import { getQueryParams } from 'store/route/route.selectors';
import { canTrackUser } from 'store/user/user.selectors';
import systemSlice from './system.slice';
import { getShowLoginLink } from './system.selectors';
import { ErrorTrace } from 'services/error/error.types';

/**
 * This saga will start as soon as the persistGate is lifted.
 */
function* initApp() {
  /**
   * do anything we want to do before allowing the app to be considered as 'started'
   */

  try {
    yield call(configureEnvironment);

    yield call(initServices);

    /**
     * start your saga flows
     */

    yield call(setLocaleByQueryParam);
    yield call(setClientIdByQueryParam);
    yield call(setShowLoginByQueryParam);
    yield call(setAnalyticsByQueryParam);

    yield call(verifyAnalyticsTracking);

    /**
     * system is ready -> time to render UI!
     */
    yield put(systemSlice.actions.SYSTEM_READY());
  } catch (e) {
    yield put(systemSlice.actions.SYSTEM_FAIL(e as ErrorTrace));
  }
}

function* configureEnvironment() {
  const response: AxiosResponse<EnvApiConfiguration> = yield axios.get(
    '/env.json',
    { headers: { 'Content-Type': 'application/json' } },
  );
  envService.setApiConfiguration(response.data);
}

function* setClientIdByQueryParam() {
  const params: SagaReturnType<typeof getQueryParams> = yield select(
    getQueryParams,
  );
  const clientIdParam =
    params[QUERY_PARAMS.CLIENT_ID] || envService.IDP_CLIENT_ID;

  if (clientIdParam && typeof clientIdParam === 'string') {
    yield put(systemSlice.actions.SYSTEM_SET_CLIENT_ID(clientIdParam));
  }
}

function* setShowLoginByQueryParam() {
  const showLoginLink: SagaReturnType<typeof getShowLoginLink> = yield select(
    getShowLoginLink,
  );

  const params: SagaReturnType<typeof getQueryParams> = yield select(
    getQueryParams,
  );
  const showLoginLinkParam = params[QUERY_PARAMS.SHOW_LOGIN_LINK];

  const shouldShowLoginLink =
    !showLoginLinkParam ||
    (showLoginLinkParam !== '' && showLoginLinkParam === 'true');

  if (showLoginLink !== shouldShowLoginLink) {
    yield put(systemSlice.actions.SYSTEM_SHOW_LOGIN_LINK(shouldShowLoginLink));
  }
}

export function* setAnalyticsByQueryParam() {
  const params: SagaReturnType<typeof getQueryParams> = yield select(
    getQueryParams,
  );
  const analyticsParam = params[QUERY_PARAMS.ANALYTICS_CONSENT_GIVEN];

  if (analyticsParam === undefined) {
    return;
  } else if (analyticsParam === 'true') {
    yield put(
      userSlice.actions.SET_COOKIES_LOCAL({
        essential: true,
        analytics: true,
      }),
    );
  } else {
    yield put(userSlice.actions.SET_COOKIES_LOCAL(undefined));
  }
}

export function* verifyAnalyticsTracking() {
  const canTrack: ReturnType<typeof canTrackUser> = yield select(canTrackUser);
  yield call([gaService, canTrack ? gaService.enable : gaService.disable]);
}

const systemSaga = function* () {
  yield all([takeLatest(systemSlice.actions.SYSTEM_START, initApp)]);
};

export default systemSaga;
