import React from 'react';
import { Box } from '@material-ui/core';

const HighlightBox: React.FC = ({ children }) => (
  <Box
    component="span"
    display="block"
    pt={4}
    pb={4}
    pl={6}
    pr={6}
    color="neutral03.400"
    bgcolor="neutral03.100"
    fontWeight="bold"
    borderRadius={4}
    style={{ wordBreak: 'break-word' }}
  >
    {children}
  </Box>
);

export default HighlightBox;
