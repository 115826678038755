import React, { ComponentProps } from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { ReactComponent as IcoLogo } from 'assets/svg/u_ico_logo.svg';

const Logo: React.FC<ComponentProps<typeof IcoLogo>> = ({
  title = 'Vanbreda Risk & Benefits',
  ...rest
}) => {
  const theme = useTheme();
  const isOnPhone = useMediaQuery(theme.breakpoints.down('lg'));

  const size = isOnPhone
    ? { width: 162, height: 72 }
    : { width: 225, height: 100 };

  return (
    <IcoLogo title={title} width={size.width} height={size.height} {...rest} />
  );
};

export default Logo;
