import { AxiosInstance } from 'axios';

import RestApiService from './';
import { Application } from './dashboard.definitions';

export async function getApplications(this: RestApiService<AxiosInstance>) {
  const response = await this.api.get<{ data: Array<Application> }>(
    '/api/applications',
  );
  return response;
}
