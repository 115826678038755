import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  useTheme,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Box,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import { ReactComponent as IcoProfile } from 'assets/svg/u_ico_clients.svg';
import { ReactComponent as IcoSettings } from 'assets/svg/u_ico_s_fallback.svg';
import { ReactComponent as IcoLogout } from 'assets/svg/u_ico_logout.svg';
import { ReactComponent as IcoArrowRight } from 'assets/svg/u_ico_arrow_right.svg';
import { Routes } from 'pages/routes.constants';

interface Props {
  onLogout: () => void;
}

const MyData: React.FC<Props> = ({ onLogout }) => {
  const theme = useTheme();

  return (
    <>
      <Typography variant="h3" gutterBottom>
        <FormattedMessage id="dashboard.overview.data.title" />
      </Typography>

      <List>
        <ListItem button component={RouterLink} to={Routes.Profile}>
          <ListItemIcon>
            <IcoProfile
              title=""
              width={24}
              height={24}
              fill={theme.palette.primary.main}
            />
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography component="span" color="primary">
              <FormattedMessage id="dashboard.overview.data.profile" />
            </Typography>
          </ListItemText>
          <IcoArrowRight
            title=""
            width={24}
            height={24}
            fill={theme.palette.primary.main}
          />
        </ListItem>

        <ListItem button component={RouterLink} to={Routes.Settings}>
          <ListItemIcon>
            <IcoSettings
              title=""
              width={24}
              height={24}
              fill={theme.palette.primary.main}
            />
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography component="span" color="primary">
              <FormattedMessage id="dashboard.overview.data.settings" />
            </Typography>
          </ListItemText>
          <IcoArrowRight
            title=""
            width={24}
            height={24}
            fill={theme.palette.primary.main}
          />
        </ListItem>

        <Box mt={2} mb={2}>
          <Divider />
        </Box>

        <ListItem button onClick={onLogout}>
          <ListItemIcon>
            <IcoLogout
              title=""
              width={24}
              height={24}
              fill={theme.palette.primary.main}
            />
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography component="span" color="primary">
              <FormattedMessage id="general.logout.label" />
            </Typography>
          </ListItemText>
          <IcoArrowRight
            title=""
            width={24}
            height={24}
            fill={theme.palette.primary.main}
          />
        </ListItem>
      </List>
    </>
  );
};

export default MyData;
