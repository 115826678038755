import React, { FC } from 'react';

import { Grid, Typography } from '@material-ui/core';

import { FormattedMessage } from 'react-intl';

import { FormPassword, FormTextField } from 'components/@common/Form';
import { Form } from 'formik';
import { FIELD_NAME } from './constants';

const ItsmeSettingsForm: FC = ({ children }) => {
  return (
    <Form>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Typography variant="h3" color="textPrimary">
            <FormattedMessage id="dashboard.settings.edit_itsme_settings.section.unlink.title" />
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="caption" color="textSecondary">
            <FormattedMessage id="dashboard.settings.edit_itsme_settings.section.unlink.description" />
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <FormTextField
            type="email"
            name={FIELD_NAME.EMAIL}
            label={<FormattedMessage id="form.email.label" />}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <FormPassword
            name={FIELD_NAME.PASSWORD}
            label={<FormattedMessage id="form.password_new.label" />}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormPassword
            name={FIELD_NAME.PASSWORD_NEW_CONFIRM}
            autoComplete="new-password"
            label={<FormattedMessage id="form.password_repeat.label" />}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Form>
  );
};

export default ItsmeSettingsForm;
