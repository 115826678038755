import React from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { gaService } from 'services';
import formEditPasswordSlice from 'store/form-edit-password/formEditPassword.slice';
import { isSubmittingForm } from 'store/form-edit-password/formEditPassword.selectors';
import EditPasswordForm from './EditPasswordForm';
import { EditPasswordValues } from './EditPasswordForm.types';
import { ValidationSchema } from './EditPasswordForm.validation';

interface Props {
  onSuccess?: () => void;
}

const EditPasswordFormContainer: React.FC<Props> = ({
  onSuccess = () => console.info('success'),
}) => {
  const dispatch = useDispatch();
  const isSubmitting = useSelector(isSubmittingForm);

  const handleSubmit = (values: EditPasswordValues) => {
    gaService.trackEditUserPassword();

    dispatch(
      formEditPasswordSlice.actions.FORM_SUBMIT_START({
        passwords: {
          passwordOld: values.passwordOld,
          passwordNew: values.passwordNew,
        },
        onSuccess, // callback so we can reset local state
      }),
    );
  };

  return (
    <Formik<EditPasswordValues>
      initialValues={{
        passwordOld: '',
        passwordNew: '',
        passwordNewConfirm: '',
      }}
      validationSchema={ValidationSchema}
      validateOnMount
      isInitialValid={false}
      onSubmit={handleSubmit}
    >
      <EditPasswordForm isSubmitting={isSubmitting} />
    </Formik>
  );
};

export default EditPasswordFormContainer;
