import React from 'react';
import { Typography, Box, Button, Grid, useTheme } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as IcoCheck } from 'assets/svg/u_ico_feedback_succes.svg';
import { MfaMethod } from 'services/api/user.definitions';
import mapAuthMethod from './map-auth-method';

interface Props {
  enabled?: boolean;
  required?: boolean;
  methods?: MfaMethod[];
  onActivate?: () => void;
  onDeactivate?: () => void;
  onReset?: () => void;
}

const Authentication: React.FC<Props> = ({
  enabled = false,
  required = true,
  methods = [],
  onActivate = () => console.info('clicked activate'),
  onDeactivate = () => console.info('clicked deactivate'),
  onReset = () => console.info('clicked reset'),
}) => {
  const theme = useTheme();
  return (
    <section>
      <Box mb={4}>
        <Typography component="h3" variant="h3" color="textPrimary">
          <FormattedMessage id="dashboard.settings.auth.title" />
        </Typography>
      </Box>

      <Box mb={4}>
        {methods.length > 0 && enabled ? (
          <>
            <Box mb={6}>
              <Typography component="h4" variant="h4" color="primary">
                <FormattedMessage id="dashboard.settings.auth.methods.title" />
              </Typography>
            </Box>

            <Box mb={6}>
              {methods.map((method, i) => {
                const methodConfig = mapAuthMethod(method);
                return (
                  methodConfig && (
                    <Box
                      key={method}
                      display="flex"
                      mb={i === methods.length - 1 ? 0 : 4}
                    >
                      <Box mr={6}>
                        <methodConfig.icon width={48} height={48} />
                      </Box>
                      <Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          mb={1}
                        >
                          <Typography variant="h5" color="textSecondary">
                            <FormattedMessage id={methodConfig.title} />
                          </Typography>
                          <IcoCheck
                            title="Checked"
                            width={32}
                            height={32}
                            fill={theme.palette.accent01[400]}
                          />
                        </Box>
                        <Typography color="textSecondary">
                          <FormattedMessage id={methodConfig.description} />
                        </Typography>
                      </Box>
                    </Box>
                  )
                );
              })}
            </Box>

            <Grid container spacing={4}>
              <Grid item xs={required ? 12 : 6}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={onReset}
                >
                  <FormattedMessage id="dashboard.settings.auth.button.reset" />
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Box mb={6}>
              <Typography color="textSecondary">
                <FormattedMessage id="dashboard.settings.auth.description" />
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={onActivate}
            >
              <FormattedMessage id="dashboard.settings.auth.button.setup" />
            </Button>
          </>
        )}
      </Box>

      <Typography variant="caption" color="textSecondary">
        <FormattedMessage id="dashboard.settings.auth.meta.logout_information" />
      </Typography>
    </section>
  );
};

export default Authentication;
