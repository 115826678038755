export enum Flows {
  Activate = 'activate',
  LinkAccount = 'link-account',
  Register = 'register',
  RegisterForm = 'register-form',
}

export enum Origins {
  App = 'app',
  Web = 'web',
}

export type DecodedState = Partial<{
  flow: Flows;
  origin: Origins;
}>;
