import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const ButtonStyled = styled(Button)`
  background-color: #ff4612;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  height: 56px;
  text-transform: none;
  width: 100%;
  font-family: RobotoSlab, sans-serif;
`;
