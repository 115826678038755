import React from 'react';
import {
  Typography,
  Box,
  ListItem,
  ListItemText,
  useTheme,
  Divider,
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { ReactComponent as IcoExternal } from 'assets/svg/u_ico_external_link.svg';
import { ReactComponent as IcoArrowRight } from 'assets/svg/u_ico_arrow_right.svg';

interface Props {
  onEditConsent?: () => void;
}

const Privacy: React.FC<Props> = ({
  onEditConsent = () => console.info('clicked edit consent'),
}) => {
  const theme = useTheme();
  const intl = useIntl();

  return (
    <section>
      <Box mb={4}>
        <Typography component="h3" variant="h3" color="textPrimary">
          <FormattedMessage id="dashboard.settings.privacy.title" />
        </Typography>
      </Box>

      <ListItem button onClick={onEditConsent}>
        <ListItemText disableTypography>
          <Typography component="span" color="primary">
            <FormattedMessage id="dashboard.settings.privacy.edit_cookie_preferences" />
          </Typography>
        </ListItemText>
        <IcoArrowRight
          title=""
          width={24}
          height={24}
          fill={theme.palette.primary.main}
        />
      </ListItem>

      <Box mt={2} mb={2}>
        <Divider />
      </Box>

      <ListItem
        button
        component="a"
        target="__blank"
        href={intl.formatMessage({
          id: 'dashboard.settings.privacy.ask_question_about_data.link',
        })}
        rel="noreferrer noopener"
      >
        <ListItemText disableTypography>
          <Typography component="span" color="primary">
            <FormattedMessage id="dashboard.settings.privacy.ask_question_about_data" />
          </Typography>
        </ListItemText>
        <IcoExternal
          title=""
          width={24}
          height={24}
          fill={theme.palette.primary.main}
        />
      </ListItem>

      <Box mt={2} mb={2}>
        <Divider />
      </Box>

      <ListItem
        button
        component="a"
        target="__blank"
        href={intl.formatMessage({
          id: 'dashboard.settings.privacy.policy.link',
        })}
        rel="noreferrer noopener"
      >
        <ListItemText disableTypography>
          <Typography component="span" color="primary">
            <FormattedMessage id="dashboard.settings.privacy.policy" />
          </Typography>
        </ListItemText>
        <IcoExternal
          title=""
          width={24}
          height={24}
          fill={theme.palette.primary.main}
        />
      </ListItem>
    </section>
  );
};

export default Privacy;
