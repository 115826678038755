import { AxiosError } from 'axios';
import { ErrorTrace, CustomHttp } from './error.types';

/**
 * service used to unify errors across the project
 */

class ErrorService<T extends { [key: string]: { [key: string]: string } }> {
  messages: T;

  httpBaseKey = 'http.error';

  constructor(messages: T) {
    this.messages = messages;
  }

  translateHttpKey = (key: string, language: string = '') => {
    const messagesByLanguage = this.messages[language.toLowerCase()];
    return messagesByLanguage?.[`${this.httpBaseKey}.${key}`];
  };

  createHttp = (error: AxiosError) =>
    this.create<CustomHttp>({
      code: error.code ?? error.response?.status,
      message: error.message ?? error.response?.statusText,
      stack: error.stack,
      custom: {
        config: error.config,
        response: error.response,
      },
    });

  create = <T>(error: ErrorTrace<T>) => ({
    code: error.code,
    message: error.message,
    stack: error.stack,
    custom: error.custom,
  });
}

export default ErrorService;
