import { DecodedState } from 'types/state.types';

// Reverting implementation to the version of Jan 9th - debugging an issue (Benjamin)

export const decodeState = <T extends { [key: string]: unknown }>(
  state?: string,
): T | undefined => {
  if (state) {
    const decoded = atob(state);

    if (decoded) {
      return JSON.parse(decoded);
    }
  }
};

export const encodeState = (state: DecodedState) => {
  const stringified = JSON.stringify(state);

  return btoa(stringified);
};
