import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Application } from 'services/api/dashboard.definitions';
import { ErrorTrace } from 'services/error/error.types';

export type ApplicationsState = {
  data: Application[];
  isLoading: boolean;
  error?: ErrorTrace;
};

const initialState: ApplicationsState = {
  data: [],
  isLoading: false,
  error: undefined,
};

const applicationsSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    GET_APPLICATIONS_START: (state) => {
      state.isLoading = true;
      state.error = undefined;
    },
    GET_APPLICATIONS_SUCCESS: (state, action: PayloadAction<Application[]>) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    GET_APPLICATIONS_FAIL: (state, action: PayloadAction<ErrorTrace>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default applicationsSlice;
