import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Typography, Link, Button } from '@material-ui/core';

import Page from 'components/@layout/Page';
import Logo from 'components/Logo';
import HighlightBox from 'components/HighlightBox';

interface Props {
  email: string;
  webviewCloseUri?: string;
  onReset: () => void;
}

const RegisterConfirm: React.FC<Props> = ({
  email,
  webviewCloseUri,
  onReset,
}) => (
  <Page>
    <Box textAlign="center" width="100%" mb={{ xs: 7, lg: 10 }}>
      <Logo />
    </Box>

    <Typography variant="h2" color="primary" gutterBottom>
      <FormattedMessage id="register.success.title" />
    </Typography>

    <Typography color="textSecondary" gutterBottom>
      <FormattedMessage id="register.success.mail_sent_to" />
    </Typography>

    <Typography gutterBottom>
      <HighlightBox>{email}</HighlightBox>
    </Typography>

    <Typography color="textSecondary">
      <FormattedMessage id="register.success.click_link_to_confirm" />
    </Typography>

    <Box mt={6}>
      <Box display="block">
        <Link component="button" onClick={onReset}>
          <FormattedMessage id="register.success.reset" />
        </Link>
      </Box>
    </Box>

    {webviewCloseUri && (
      <Box mt={8}>
        <Button
          component="a"
          href={webviewCloseUri}
          variant="contained"
          color="primary"
          fullWidth
        >
          <FormattedMessage id="register.success.close_webview" />
        </Button>
      </Box>
    )}
  </Page>
);

export default RegisterConfirm;
